<template>
  <div>
    <div v-if="loading.page && !item.uuid" class="py-5 text-center d-block">
      <b-spinner small style="margin-right: 5px" /> carregando...
    </div>
    <div v-else>
      <b-card-actions class="card-infos mb-1" title="Informações da turma" action-collapse>
        <b-card-text>
          <div class="form-row">
            <div class="col-md-2">
              <div class="form-group">
                <strong>Código: </strong>
                {{ item.name }}
              </div>
            </div>
            <div class="col-md-7">
              <div class="form-group">
                <strong>Título: </strong>
                {{ item.course.title }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <strong>Turno: </strong>
                {{ item.shift.title }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <strong>Modalidade:</strong>
                {{ item.modality.title }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <strong>Horário:</strong>
                {{ item.start_time }} às
                {{ item.end_time }}
              </div>
            </div>
            <div v-if="item.unit" class="col-md-3">
              <div class="form-group">
                <strong>Unidade:</strong>
                {{ item.unit.title }}
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group" v-if="this.item.postponements.length > 0">
                <strong>Início:</strong>
                {{
      item.postponements[item.postponements.length - 1].date
      | datePT
    }}
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-md-0">
                <strong>Situação:</strong>
                {{ item.status.title }}
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-md-0">
                <strong>P. vendas:</strong>
                {{ item.display_panel ? "Sim" : "Não" }}
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group mb-0 mb-md-0">
                <ul class="list-inline mb-0">
                  <li><strong class="d-block mr-1">Dias de aula:
                    </strong></li>
                  <li v-for="(element, index) in item.weekdays" :key="index">
                    <b-badge pill variant="light-primary" style="margin-right: 4px;">{{
      element.title }}</b-badge>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-card-text>
      </b-card-actions>

      <div class="card mb-1">
        <div class="card-header mb-0">
          <h4 class="mb-0">
            Disciplinas
          </h4>
          <small>Em todas as aulas, está sendo subtraído o tempo de intervalo.</small>
        </div>
        <b-table-simple v-if="tableFields.length" responsive class="mb-0">
          <!-- Loading overlay -->
          <div v-if="loading.disciplines" class="loading-overlay">
            <div class="loading-content">
              <b-spinner small variant="primary" class="mr-1"></b-spinner>
              Carregando...
            </div>
          </div>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th v-for="(field, index) in tableFields" :key="`t-${index}`" :class="field.class">
                {{ field.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(discipline, index) in tableDisciplines" :key="`b-tr-${index}`">
              <b-td :class="tableFields[0].class">
                {{ discipline.lessons.length }}
              </b-td>

              <b-td :class="tableFields[1].class">
                <div class="d-flex align-items-center justify-content">
                  <div class="d-flex-full mr-1">
                    <span class="text-nowrap">
                      {{ discipline.title }}
                    </span>
                  </div>
                  <b-badge style="column-gap: 5px;" v-if="discipline.dipe" class="d-flex align-items-center justify-content" variant="danger">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#ffffff" fill="none">
                      <path d="M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z" stroke="currentColor" stroke-width="2" />
                      <path d="M11.992 16H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M12 13L12 8.99997" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>DIPE</span>
                  </b-badge>
                </div>
              </b-td>

              <b-td :class="tableFields[2].class" class="text-center">
                <i class="bi bi-star-fill" style="color: #00bb76;font-size: 0.7rem;"></i>
                {{ discipline.evaluate.total.toFixed(2) }} ({{ discipline.evaluate.quantity }})
              </b-td>

              <b-td :class="tableFields[3].class">
                <span class="text-nowrap">
                  {{ discipline.workload }}
                </span>
              </b-td>

              <b-td :class="tableFields[4].class">
                {{ discipline.category_1 ? discipline.category_1 : '-' }}
              </b-td>

              <b-td :class="tableFields[5].class">
                {{ discipline.category_2 ? discipline.category_2 : '-' }}
              </b-td>
              <b-td :class="tableFields[6].class">
                {{ discipline.category_3 ? discipline.category_3 : '-' }}
              </b-td>

              <b-td class="text-center"
                :class="compareHours(discipline.total, discipline.workload, item.interval, discipline.lessons.length).class">
                {{ compareHours(discipline.total, discipline.workload, item.interval,
      discipline.lessons.length).adjustedTotal }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <tr>
              <td colspan="3"></td>
              <td class="text-center">{{ totalWorkload }}</td>
              <td class="text-center">{{ totalCategory1 }}</td>
              <td class="text-center">{{ totalCategory2 }}</td>
              <td class="text-center">{{ totalCategory3 }}</td>
              <td></td>
            </tr>
          </b-tfoot>
        </b-table-simple>
      </div>

      <div class="form-row">
        <div class="col-md-4">
          <b-card :class="{ show: isCalendarOverlaySidebarActive }" class="mb-1">
            <h5 class="text-purple">
              <strong>Datas seleciondas</strong>
              <span class="badge badge-pill badge-primary float-right">
                {{ $store.state.Grid.days.length }}
              </span>
            </h5>
            <hr />
            <small class="d-block" :class="index !== $store.state.Grid.days.length - 1 ? 'mb-1' : ''"
              v-for="(day, index) in $store.state.Grid.days" :key="index">
              <i class="bi bi-calendar2-event" style="margin-right: 3px"></i>
              {{ index + 1 }}/{{ $store.state.Grid.days.length }} -
              {{ day }}
            </small>
            <p class="mb-0" v-if="!$store.state.Grid.days.length">
              Nenhuma data selecionada.
            </p>
          </b-card>

          <b-card>
            <h5 class="app-label">Filtrar aulas</h5>
            <hr />
            <p class="mb-1">
              Selecione os filtros abaixo para filtrar as aulas no
              calendário
            </p>

            <div class="form-group mb-2 mt-2" v-if="optionsGridTeachers.length">
              <label for="" class="mb-1">
                <i class="bi bi-caret-right-fill"></i>
                Professores
              </label>
              <b-form-checkbox-group name="ck-teachers" stacked>
                <b-form-checkbox v-for="(element, index) in optionsGridTeachers" :key="index"
                  :id="`ck-teacher-${index}`" :name="`teacher-${index}`" :value="element.code" class="cursor-pointer"
                  style="margin-bottom: 8px" v-model="filters.teachers">
                  {{ element.title }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>

            <div class="form-group mb-2" v-if="item.disciplines.length">
              <label for="" class="mb-1">
                <i class="bi bi-caret-right-fill"></i>
                Disciplinas
              </label>
              <b-form-checkbox-group name="ck-discipline" stacked>
                <b-form-checkbox v-for="(element, index) in item.disciplines" :key="index"
                  :id="`ck-discipline-${index}`" :name="`ck-n-discipline-${index}`" :value="element.code"
                  class="cursor-pointer" style="margin-bottom: 8px" v-model="filters.disciplines">
                  {{ element.title }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>

            <div class="form-group mb-2" v-if="optionsGridStatus.length">
              <label for="" class="mb-1">
                <i class="bi bi-caret-right-fill"></i>
                Status
              </label>
              <b-form-checkbox-group name="ck-status" stacked>
                <b-form-checkbox v-for="(element, index) in optionsGridStatus" :key="index" :id="`ck-status-${index}`"
                  :name="`status-${index}`" :value="element.code" class="cursor-pointer" style="margin-bottom: 8px"
                  v-model="filters.status">
                  <span :class="`text-${element.color}`">
                    {{ element.title }}
                  </span>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>

            <div class="form-group mb-0" v-if="item.categories.length">
              <label for="" class="mb-1">
                <i class="bi bi-caret-right-fill"></i>
                Categorias
              </label>
              <b-form-checkbox-group name="ck-categories" stacked>
                <b-form-checkbox v-for="(element, index) in item.categories" :key="index" :id="`ck-category-${index}`"
                  :name="`category-${index}`" :value="element.code" class="cursor-pointer" style="margin-bottom: 8px"
                  v-model="filters.categories">
                  {{ element.title }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </b-card>
        </div>

        <div class="col-md-8">
          <div class="app-calendar calendar-grid overflow-hidden border">
            <div class="row no-gutters">
              <div class="col position-relative">
                <div class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions">
                  <div class="card-header">
                    <div class="form-row justify-content-between">
                      <div class="col-md-10">
                        <p class="mb-0 font-medium-1" v-if="!this.$store.state.Grid.days.length">
                          Selecione pelo menos 1 data no calendário baixo, caso
                          queira
                          <strong class="text-black">criar(não importar)</strong>
                          uma aula.
                        </p>
                      </div>
                      <div class="col-md-2">
                        <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" text="Opções" variant="primary" right
                          block>
                          <b-dropdown-item @click="isModalGridLessonImportActive = true">
                            <i class="bi bi-arrow-down-up" style="margin-right: 3px"></i>
                            Importar grade
                          </b-dropdown-item>

                          <b-dropdown-item @click="isModalGridLessonDisciplineDeleteActive = true">
                            <i class="bi bi-trash text-danger" style="margin-right: 3px"></i>
                            Deletar por disciplinas
                          </b-dropdown-item>

                          <b-dropdown-item @click="isModalGridLessonsDeleteActive = true">
                            <i class="bi bi-trash text-danger" style="margin-right: 3px"></i>
                            Deletar aulas
                          </b-dropdown-item>

                          <b-dropdown-item @click="isModalGridLessonsEditActive = true">
                            <i class="bi bi-pencil-square" style="margin-right: 3px"></i>
                            Editar aulas
                          </b-dropdown-item>

                          <b-dropdown-item :disabled="!this.$store.state.Grid.days.length" @click="openModalNewLesson">
                            <i class="bi bi-plus-lg" style="margin-right: 3px"></i>
                            Criar aula
                          </b-dropdown-item>

                          <b-dropdown-item>
                            <i class="bi bi-send" style="margin-right: 3px"></i>
                            Cobrar professor
                          </b-dropdown-item>

                          <b-dropdown-divider />
                          <b-dropdown-item class="text-danger">
                            <i class="bi bi-trash text-danger" style="margin-right: 3px"></i>
                            Encerrar turma
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pb-0">
                    <div class="calendar-wrapper position-relative">
                      <!-- Loading overlay -->
                      <div v-if="loading.events" class="loading-overlay">
                        <div class="loading-content">
                          <b-spinner small variant="primary" class="mr-1"></b-spinner>
                          Carregando eventos...
                        </div>
                      </div>

                      <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar">
                        <template v-slot:eventContent="arg">
                          <span :id="`popover-id-${arg.event.id}`" :title="arg.event.title"
                            v-html="arg.event.title"></span>
                          <b-popover :target="`popover-id-${arg.event.id}`" :variant="arg.event.extendedProps.color"
                            triggers="hover" placement="top" :title="arg.event.title" custom-class="fc-popover">
                            <template #title>
                              <span v-html="arg.event.title"></span>
                            </template>
                            <p v-html="arg.event.extendedProps.description"></p>
                          </b-popover>
                        </template>
                      </full-calendar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-edit-lesson" centered title="Alterar aula" ok-only ok-title="Salvar" ok-variant="success"
      no-close-on-backdrop no-close-on-esc hide-header-close @hide="onHide" :visible="isEventHandlerEditLesson"
      hide-footer>
      <b-card-text v-if="lesson.uuid">
        <b-form @submit.prevent="submitUpdateLesson">
          <b-tabs pills class="tabs-details-grid-lesson">
            <b-tab active>
              <template #title>
                <span class="d-none d-sm-inline">
                  <i class="text-danger bi bi-record-circle"></i>
                  Detalhes da aula
                </span>
              </template>

              <h4 style="font-size: 1.1rem" class="text-primary mb-1">
                <i class="bi bi-hash"></i>{{ lesson.id }} -
                {{ lesson.discipline.title }}
              </h4>
              <hr />
              <div class="form-row justify-content-between">
                <div class="col-md-8 col-6">
                  <small>
                    <i class="bi bi-calendar2-event" style="margin-right: 3px"></i>
                    {{ lesson.date | datePT }}
                    <i class="bi bi-hourglass-top ml-1" style="margin-right: 3px"></i>
                    {{ lesson.workload | justTime }}
                  </small>
                </div>
                <div class="col-md-4 col-6 text-right">
                  <small><i class="bi bi-pin" style="margin-right: 3px"></i>
                    {{ lesson.number }} de
                    {{ lesson.discipline.lessons }}</small>
                </div>
              </div>
              <hr />
              <div class="form-row form-group">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Horário
                </label>
                <div class="col-md-6">
                  <div class="form-row">
                    <div class="col-md-5">
                      <input v-mask="'##:##'" v-model="lesson.start_time" type="tel" class="form-control" :class="{
      'is-invalid': $v.lesson.start_time.$error,
    }" />
                    </div>
                    <div class="col-md-2 text-center">
                      <span class="d-block" style="line-height: 3">
                        às
                      </span>
                    </div>
                    <div class="col-md-5">
                      <input type="tel" v-model="lesson.end_time" class="form-control" :class="{
      'is-invalid': $v.lesson.end_time.$error,
    }" v-mask="'##:##'" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <b-form-checkbox checked="true" class="custom-control-mode-lesson custom-control-primary"
                    name="check-button" switch v-model="lesson.live">
                    Live
                  </b-form-checkbox>
                </div>
              </div>

              <div class="form-row form-group">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Professor
                </label>
                <div class="col">
                  <v-select :searchable="true" label="title" v-model="lesson.teacher" :class="{
      'is-invalid': $v.lesson.teacher.$error,
    }" item-text="title" item-value="code" placeholder="Digite o título"
                    :options="optionsTeachers">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>

              <div class="form-row form-group">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Categoria
                </label>
                <div class="col">
                  <v-select :searchable="false" label="title" v-model="lesson.category" :class="{
      'is-invalid': $v.lesson.category.$error,
    }" item-text="title" item-value="code" placeholder="Digite o título"
                    :options="optionsGridLessonCategory">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>

              <div class="form-row form-group">
                <label class="col-md-3 col-form-label"> Materiais </label>
                <div class="col">
                  <v-select multiple label="title" item-text="title" item-value="code" v-model="lesson.materials"
                    placeholder="Digite o título do material" :options="optionsMaterials" @search="fetchMaterials">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>

              <div class="form-row form-group">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle" v-if="lesson.live"></i>
                  Cód. vídeo
                </label>
                <div class="col">
                  <input type="text" class="form-control" v-model="lesson.video_code" :class="{
      'is-invalid': $v.lesson.video_code.$error,
    }" />
                </div>
              </div>

              <div class="form-row form-group mb-0">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Status
                </label>
                <div class="col">
                  <v-select :searchable="false" label="title" v-model="lesson.status"
                    :class="{ 'is-invalid': $v.lesson.status.$error }" item-text="title" item-value="code"
                    placeholder="Digite o título" :options="optionsGridLessonStatus">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <template #title>
                <span class="d-none d-sm-inline">
                  Grades
                  <b-badge v-if="lesson.grids && lesson.grids.length > 1">
                    {{ lesson.grids.length }}
                  </b-badge>
                </span>
              </template>

              <div class="form-row mb-2">
                <div class="col-md-10">
                  Você também pode vincular a aula a outra grade.
                </div>
                <div class="col-md-2">
                  <button @click="lessonToGrid(lesson.uuid)" type="button" class="btn btn-sm btn-primary">
                    <feather-icon icon="PlusIcon" class="cursor-pointer cursor" size="16" />
                  </button>
                </div>
              </div>
              <b-table v-if="lesson.grids.length" sticky-header class="position-relative" :items="lesson.grids"
                responsive :fields="fieldsGrids" primary-key="id" show-empty empty-text="Nenhum registro encontrado">
                <template #cell(uuid)="{ item }">
                  {{ item.name }} - {{ item.course_title }}
                </template>

                <template #cell(action)="data">
                  <feather-icon icon="EditIcon" class="cursor-pointer cursor" size="16"
                    v-if="item.uuid !== data.item.uuid" @click="
                      $router.push({
                        name: 'grids-edit',
                        params: { uuid: data.item.uuid },
                      })
                      " />
                </template>
              </b-table>

              <p v-else>
                Esta aula não está vinculada a nenhuma outra grade até o
                momento.
              </p>
            </b-tab>
          </b-tabs>
          <hr />
          <div class="form-row justify-content-between">
            <div class="col-md-2">
              <button @click="lesson.grids.length > 1 ? $bvModal.show('modal-lesson-delete') : confirmDelete()" type="button" class="btn btn-danger btn-block">
                <i class="bi bi-trash3"></i>
              </button>
            </div>
            <div class="col-md-3 offset-md-4">
              <button @click="closeModal" type="button" class="btn btn-light btn-block">
                Fechar
              </button>
            </div>
            <div class="col-md-3">
              <button type="submit" class="btn btn-success btn-block">
                Salvar
              </button>
            </div>
          </div>
        </b-form>
      </b-card-text>
      <div v-else-if="!lesson.uuid" class="d-block text-center py-3">
        <b-spinner small style="margin-right: 5px" /> carregando...
      </div>
    </b-modal>

    <b-modal id="modal-new-lesson" centered title="Cadastrar aula " ok-only ok-title="Cadastrar" ok-variant="success"
      no-close-on-backdrop no-close-on-esc hide-header-close @hide="onHide" :visible="isEventHandlerSidebarActive"
      hide-footer modal-class="modal-new-lesson">
      <b-card-text>
        <b-form @submit.prevent="submitNewLesson">
          <div v-if="disciplineSelected" class="form-row justify-content-between">
            <div class="col-md-8">
              <h4 class="text-primary" style="margin-bottom: 5px">
                {{ disciplineSelected.title }}
              </h4>
              <strong class="d-block">
                Aulas:
                {{ formLesson.days.length }}
              </strong>
            </div>
            <div class="col-md-4 text-right">
              <span class="d-block" style="margin-bottom: 5px">Carga horária
              </span>
              <strong class="d-block">
                {{ disciplineSelected.workload }}h
              </strong>
            </div>
          </div>
          <div class="d-block mb-2">
            <hr v-if="disciplineSelected" />
            <div class="form-row form-group">
              <label class="col-md-3 col-form-label">
                <i class="text-danger bi bi-record-circle"></i>
                Disciplina
              </label>
              <div class="col">
                <v-select :disabled="!days.length" :searchable="false" label="title" v-model="disciplineSelected"
                  item-text="title" item-value="code" placeholder="Digite o título" :options="item.disciplines"
                  @input="changeDiscipline(disciplineSelected)">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
            <div class="form-row form-group">
              <label class="col-md-3 col-form-label">
                <i class="text-danger bi bi-record-circle"></i>
                Professor
              </label>
              <div class="col">
                <v-select :searchable="true" label="title" v-model="formLesson.teacher" :class="{
      'is-invalid': $v.formLesson.teacher.$error,
    }" item-text="title" item-value="code" :options="optionsTeachers">
                  <span slot="no-options">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </div>
            <div class="d-block">
              <div class="form-row form-group">
                <label class="col-md-3 col-form-label"> Materiais </label>
                <div class="col">
                  <v-select multiple label="title" item-text="title" item-value="code" v-model="formLesson.materials"
                    placeholder="Digite o título do material" :options="optionsMaterials" @search="fetchMaterials">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>

              <div class="form-row form-group mb-0">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Status
                </label>
                <div class="col">
                  <v-select :searchable="false" label="title" v-model="formLesson.status" :class="{
      'is-invalid': $v.formLesson.status.$error,
    }" item-text="title" item-value="code" placeholder="Digite o título"
                    :options="optionsGridLessonStatus">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="form-row">
            <div class="col-md-11">
              <h5 class="mb-0">
                Datas selecionadas: <strong>{{ formLesson.days.length }}</strong>
              </h5>
              <p class="mb-0" style="margin-top: 5px;">
                Ao preencher o horário da aula, será verificado se existe aula no mesmo dia e horário.
              </p>
            </div>
            <div class="col-md-1">
              <button type="button" @click="addDay" class="btn btn-block btn-primary"
                style="padding: 0px 3px !important; height: 63px">
                <i class="bi bi-plus"></i>
              </button>
            </div>
          </div>
          <hr />

          <div v-if="formLesson.days.length" class="form-row mb-1" v-for="(day, index) in formLesson.days" :key="index"
            style="align-items: center;">
            <div class="col-md-1 text-center"
              style="align-content: center; justify-content: left; align-items: center; column-gap: 20px;">
              {{ formLesson.days[index].number }}/{{ days.length }}
            </div>
            <div class="col-md-3">
              <flat-pickr v-model="day.date" class="form-control" :config="{
      altInput: true,
      dateFormat: 'd/m/Y',
      altFormat: 'd/m/Y',
      locale: 'pt',
    }" />
            </div>

            <div class="col-md-4">
              <div class="d-flex" style="align-items: center;">
                <input type="tel" :class="{
      'is-invalid': $v.formLesson.days.$each[index].start.$error,
    }" placeholder="H. Início" v-model="day.start" class="form-control mr-1" v-mask="['##:##']" />
                <input type="tel" :class="{
      'is-invalid': $v.formLesson.days.$each[index].end.$error,
    }" placeholder="H. Fim" v-model="day.end" class="form-control" v-mask="['##:##']" />
              </div>
            </div>

            <div class="col-md-3">
              <v-select :searchable="false" label="title" v-model="day.category" :class="{
      'is-invalid': $v.formLesson.days.$each[index].category.$error,
    }" item-text="title" item-value="code" placeholder="Categoria"
                :options="optionsGridLessonCategory">
                <span slot="no-options">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>

            <div class="col-md-1">
              <button @click="deleteLesson(index)" type="button" class="btn btn-block btn-danger btn-sm"
                style="height: 44px;padding: 0px !important">
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
          <hr />

          <div class="form-row justify-content-end">
            <div class="col-md-2">
              <button @click="closeModal" type="button" class="btn btn-light btn-block">
                Fechar
              </button>
            </div>
            <div class="col-md-3">
              <button type="submit" class="btn btn-success btn-block">
                Cadastrar
              </button>
            </div>
          </div>
        </b-form>
      </b-card-text>
    </b-modal>

    <b-modal id="modal-delete-import-grid" centered title="Deletar aulas por disciplinas " ok-only ok-title="Deletar"
      ok-variant="success" no-close-on-backdrop no-close-on-esc hide-header-close @hide="onHide"
      :visible="isModalGridLessonDisciplineDeleteActive" hide-footer>
      <b-card-text>
        <p>Serão removidas da grade, todas as aulas das disciplinas selecionadas.</p>
        <b-list-group-item v-for="(discipline, index) in item.disciplines" :key="index">
          <div class="d-flex">
            <b-form-checkbox :checked="true" :value="discipline.code" class="custom-control-primary"
              :name="`discipline-del-${index}`" :id="`discipline-del-${index}`" switch
              v-model="formDeleteLessonsOfDisciplines">
            </b-form-checkbox>
            <div class="d-flex align-items-center justify-content">
                <div class="d-flex-full mr-1">
                  <span class="text-nowrap">
                    {{ discipline.title }} ({{discipline.lessons.length}})
                  </span>
                </div>
                <b-badge style="column-gap: 5px;" v-if="discipline.dipe" class="d-flex align-items-center justify-content" variant="danger">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#ffffff" fill="none">
                    <path d="M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z" stroke="currentColor" stroke-width="2" />
                    <path d="M11.992 16H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 13L12 8.99997" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span>DIPE</span>
                </b-badge>
              </div>
          </div>
        </b-list-group-item>

        <hr />

        <div class="form-row justify-content-end">
          <div class="col-md-3">
            <button @click="isModalGridLessonDisciplineDeleteActive = false" type="button"
              class="btn btn-light btn-block">
              Fechar
            </button>
          </div>
          <div class="col-md-4">
            <button :disabled="submitedImport" @click="$bvModal.show('modal-lesson-delete-discipline')" type="button" class="btn btn-danger btn-block">
              <span v-if="submitedImport">
                <b-spinner small variant="light" />
              </span>
              <span v-else> Remover da grade </span>
            </button>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <b-modal id="modal-delete-lessons" centered title="Deletar aulas selecionadas " ok-only ok-title="Deletar"
      ok-variant="success" no-close-on-backdrop no-close-on-esc hide-header-close @hide="onHide"
      :visible="isModalGridLessonsDeleteActive" hide-footer>
      <b-card-text>
        <form @submit.prevent="submitDeleteLessons">
          <h5>Aulas </h5>
          <p>Selecione as aulas que você pretende deletar.</p>
          <b-list-group-item style="" v-for="(discipline, index) in item.disciplines" :key="index">
            <span>
              {{ discipline.title }}
            </span>
            <ul style="list-style: none; line-height: 2.5;padding: 0px;" class="mb-0">
              <li v-for="(item, i) in discipline.lessons" :key="`${i}`">
                <div class="d-flex">
                  <div>
                    <b-form-checkbox :checked="true" :value="item.uuid" class="custom-control-primary"
                      :name="`discipline-${index}-${i}`" :id="`discipline-${index}-${i}`" switch
                      v-model="formatDeleteLessons">
                    </b-form-checkbox>
                  </div>
                  <div>
                    {{ item.date | datePT() }} - {{ formatTimer(item.start_time) }} às {{
      formatTimer(item.end_time) }}
                  </div>
                </div>
              </li>
            </ul>
          </b-list-group-item>

          <hr />

          <div class="form-row justify-content-end">
            <div class="col-md-3">
              <button @click="isModalGridLessonsDeleteActive = false" type="button" class="btn btn-light btn-block">
                Fechar
              </button>
            </div>
            <div class="col-md-4">
              <button :disabled="!formatDeleteLessons.length" type="submit" class="btn btn-danger btn-block">
                <span v-if="submittedDeleteLessons">
                  <b-spinner small variant="light" />
                </span>
                <span v-else>Deletar aulas</span>
              </button>
            </div>
          </div>
        </form>
      </b-card-text>
    </b-modal>

    <b-modal id="modal-edit-lessons" centered title="Editar aulas" ok-only ok-title="Editar aulas" ok-variant="success"
      no-close-on-backdrop no-close-on-esc hide-header-close @hide="onHide" :visible="isModalGridLessonsEditActive"
      modal-class="modal-edit-lessons" hide-footer>
      <b-card-text>
        <form @submit.prevent="submitEditLessons">
          <h5>Aulas </h5>
          <p>Altere as informações das aulas que você deseja editar.</p>
          <app-collapse accordion type="margin" class="collapse-disciplines">
            <app-collapse-item v-for="(discipline, index) in formEditLessons" :key="`disc-${index}`"
              :title="`(${discipline.lessons.length}) - ${discipline.title}`">
              <div class="form-row mb-1" v-for="(lesson, i) in discipline.lessons" :key="`lesson-${i}`"
                style="align-items: center;">
                <div class="col-md-4">
                  <flat-pickr v-model="lesson.date" class="form-control" :config="{
      altInput: true,
      dateFormat: 'Y/m/d',
      altFormat: 'd/m/Y',
      locale: 'pt',
    }" />
                </div>

                <div class="col-md-4">
                  <div class="d-flex" style="align-items: center;">
                    <input type="tel"
                      :class="{ 'is-invalid': $v.formEditLessons.$each[index].lessons.$each[i].start_time.$error }"
                      v-model="lesson.start_time" class="form-control mr-1" v-mask="['##:##']" />
                    <input type="tel"
                      :class="{ 'is-invalid': $v.formEditLessons.$each[index].lessons.$each[i].end_time.$error }"
                      v-model="lesson.end_time" class="form-control" v-mask="['##:##']" />
                  </div>
                </div>

                <div class="col-md-4">
                  <v-select :searchable="false" label="title" v-model="lesson.category" item-text="title"
                    item-value="code" :class="{
      'is-invalid': $v.formEditLessons.$each[index].lessons.$each[i].category.$error,
    }" placeholder="Categoria" :options="optionsGridLessonCategory">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>

                <!-- <div class="col-md-4">
                            <v-select :searchable="true" label="title" v-model="lesson.teacher" item-text="title" item-value="code" placeholder="Digite o título"
                              :options="optionsTeachersByDiscipline" @search="fetchTeachersByDiscipline(discipline.uuid)">
                              <span slot="no-options">
                                Nenhum registro encontrado
                              </span>
                            </v-select>
                          </div> -->
              </div>

              <p class="mb-0 p-1" v-if="!discipline.lessons.length">
                <i class="bi bi-info-circle"></i>
                Nenhnuma aula cadastrada até o momento.
              </p>
            </app-collapse-item>
          </app-collapse>

          <hr />

          <div class="form-row justify-content-end">
            <div class="col-md-3">
              <button @click="isModalGridLessonsEditActive = false" type="button" class="btn btn-light btn-block">
                Fechar
              </button>
            </div>
            <div class="col-md-4">
              <button type="submit" class="btn btn-success btn-block">
                <span v-if="submittedEditLessons">
                  <b-spinner small variant="light" />
                </span>
                <span v-else>Editar aulas</span>
              </button>
            </div>
          </div>
        </form>
      </b-card-text>
    </b-modal>

    <b-modal id="modal-import-grid" centered title="Importar grade " ok-only ok-title="Importar" ok-variant="success"
      no-close-on-backdrop no-close-on-esc hide-header-close @hide="onHide" :visible="isModalGridLessonImportActive"
      hide-footer>
      <b-card-text>
        <form @submit.prevent="submitLessonImports">
          <div class="d-block mb-2">
            <div class="form-row">
              <div class="col-12">
                <div class="form-group">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Qual grade deseja importar?
                  </label>
                  <v-select label="title" item-text="title" item-value="code" v-model="formImportLessons.grid"
                    placeholder="Digite o título" @search="fetchGrid" :options="optionsGrids" @input="gridSelected" />
                </div>
              </div>

              <div v-if="optionsDisciplines.length" class="col-12">
                <div class="form-group">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Selecione 1 ou mais disciplinas
                  </label>
                  <b-list-group>
                    <b-list-group-item v-for="(discipline, index) in optionsDisciplines" :key="index">
                      <div class="d-flex">
                        <b-form-checkbox :checked="true" :value="discipline.code" class="custom-control-primary"
                          :name="`discipline-${index}`" :id="`discipline-${index}`" switch
                          v-model="formImportLessons.disciplines">
                        </b-form-checkbox>
                        <span>
                          {{ discipline.title }} <small>({{ discipline.lessons }})</small>
                        </span>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label>
                    Deseja importar a partir de alguma data?
                  </label>
                  <flat-pickr v-model="formImportLessons.date" class="form-control" :config="{
                    dateFormat: 'd/m/Y',
                    altFormat: 'Y-m-d',
                    locale: 'pt',
                    enableTime: false,
                    wrap: true,
                  }" />
                </div>
                <small><i class="bi bi-info-circle"></i> Se nenhuma data for selecionada, as aulas programadas
                  para ocorrer a partir de hoje serão importadas.</small>
              </div>
            </div>
          </div>

          <hr />

          <div class="form-row justify-content-end">
            <div class="col-md-3">
              <button @click="isModalGridLessonImportActive = false" type="button" class="btn btn-light btn-block">
                Fechar
              </button>
            </div>
            <div class="col-md-3">
              <button :disabled="submitedImport" type="submit" class="btn btn-success btn-block">
                <span v-if="submitedImport">
                  <b-spinner small variant="light" />
                </span>
                <span> Importar </span>
              </button>
            </div>
          </div>
        </form>
      </b-card-text>
    </b-modal>

    <b-modal id="modal-lessons-exists" centered title="Horários já reservados" no-close-on-backdrop no-close-on-esc
      hide-footer>
      <p class="alert alert-danger p-1">Ops..não foi possível cadastrar as aulas, pois encontramos alguns
        conflitos referente a: data, horário e professor(a).</p>
      <b-table responsive :items="lessonsExist" :fields="tableFieldsLessonsExists" class="mb-0">
        <template #cell(grid_id)="{ item }">
          <a class="text-link" :href="`/pedagogical/units/grids/${item.grid_uuid}/edit`" target="_blank">
            {{ item.grid_id }}
          </a>
        </template>
        <template #cell(start)="{ item }">
          {{ item.start }} às {{ item.end }}
        </template>
      </b-table>
    </b-modal>

    <b-modal id="modal-lesson-to-grid" modal-class="modal-create" title="Vincular aula a outra grade" centered size="lg"
      hide-footer>
      <h5><i class="text-danger bi bi-record-circle"></i> Escolha a turma que você deseja montar grade.</h5>
      <div class="alert alert-primary">
        <div class="alert-body">
          Uma turma só poderá ter 1 grade. <br>Se a turma não aparecer na listagem, talvez já esteja vinculada a
          outra grade.
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-10">
          <v-select label="title" item-text="title" item-value="code"
            :class="{ 'is-invalid': $v.formLessonToGrid.grid.$error }" v-model="formLessonToGrid.grid"
            placeholder="Digite o CÓDIGO DA TURMA. Ex.: DL259" :options="optionsLessonToGrids"
            @search="fetchLessonToGrid">
            <span slot="no-options"> Nenhum registro encontrado </span>
            <template v-slot:option="option">
              <span :class="option.icon"></span>
              {{ option.title }}
            </template>
          </v-select>
        </div>
        <div class="col-md-2">
          <button @click="submitLessonToGrid" style="padding: 15px 10px !important" class="btn btn-block btn-primary">
            Montar Grade
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-lesson-delete" modal-class="modal-create" title="Esta aula está vinculada a mais de uma grade"
      centered size="sm" hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="alert alert-danger p-1">
        <h4 class="text-danger mb-1"><strong>Modo de exclusão da aula</strong></h4>
        <p class="text-danger">
          Você pode excluir esta aula <strong><u>apenas desta grade</u></strong> ou de <strong><u>todas as grades</u></strong>.
        </p>
      </div>
      <v-select v-model="lessonDeleteMode" :searchable="false" label="title" item-text="title" item-value="code"
        placeholder="Selecione"
        :options="optionsModeDeleteLesson" />
     
      <button @click="optionDeleteSelected" :disabled="loading.lessonDelete" type="button" class="btn btn-danger btn-block mt-2">
        Confirmar
      </button>
    </b-modal>

    <b-modal id="modal-lesson-delete-discipline" modal-class="modal-create" title="Exclusão de aulas por disciplinas"
      centered size="sm" hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="alert alert-danger p-1">
        <h4 class="text-danger mb-1"><strong>Modo de exclusão das aulas</strong></h4>
        <p class="text-danger">
          Você pode excluir as aulas <strong><u>apenas desta grade</u></strong> ou de <strong><u>todas as grades</u></strong>.
        </p>
      </div>
      <v-select v-model="lessonDeleteMode" :searchable="false" label="title" item-text="title" item-value="code"
        placeholder="Selecione"
        :options="optionsModeDeleteLesson" />
     
      <button @click="deleteLessonByDiscipline" :disabled="loading.lessonDelete" type="button" class="btn btn-danger btn-block mt-2">
        Confirmar
      </button>
    </b-modal>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BTfoot,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  BCardFooter,
  BCardHeader,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
  VBToggle,
  BButton,
  BModal,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BTooltip,
  BBadge,
  VBPopover,
  BPopover,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BAlert,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BTable
} from "bootstrap-vue";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import vSelect from "vue-select";
import { minLength, required, requiredIf } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import FullCalendar from "@fullcalendar/vue";
import useCalendar from "./useCalendar";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";

import GridLessonNew from "@/components/GridLessonNew";
import GridLessonImport from "@/components/GridLessonImport";

import { mapState } from "vuex";
import moment from "moment";
import teacher from "@/store/teacher";

export default {
  components: {
    GridLessonNew,
    GridLessonImport,
    flatPickr,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BTfoot,
    BCardActions,
    FullCalendar,
    CalendarSidebar,
    CalendarEventHandler,
    CustomPaginateTable,
    ButtonsActionsFooter,
    BSpinner,
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardHeader,
    BCardText,
    BCardFooter,
    draggable,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BTooltip,
    VBTooltip,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    BPopover,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BTabs,
    BAlert,
    BTab,
    BTable,
  },
  data() {
    return {
      loading: {
        page: true,
        events: false,
        disciplines: false,
        lesson: false,
        lessonDelete: false,
      },
      submittedDeleteLessons: false,
      submittedEditLessons: false,
      formatDeleteLessons: [],
      formImportLessons: {
        grid: "",
        disciplines: [],
        date: "",
      },
      formLessonToGrid: {
        grid: '',
        lesson: '',
      },
      formDeleteLessonsOfDisciplines: [],
      optionsGrids: [],
      optionsDisciplines: [],
      loadingLesson: false,
      submitedFind: false,
      tableDisciplines: [],
      showAllFilters: true,
      nextIdPostponements: 0,
      nextIdWorkDays: 0,
      submited: false,
      submitedImport: false,
      disciplineSelected: "",
      optionsTeachersByDiscipline: [],
      eventSelected: "",
      class_uuid: "",
      fieldsGrids: [
        {
          key: "uuid",
          label: "Grade",
          sortable: false,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "action",
          label: "",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      tableFieldsLessonsExists: [
        {
          key: "grid_id",
          label: "Grade",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "classroom",
          label: "Turma",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "date",
          label: "Data",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "start",
          label: "Horário",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isModalGridLessonImportActive: false,
      isModalGridLessonDisciplineDeleteActive: false,
      isModalGridLessonsDeleteActive: false,
      isModalGridLessonsEditActive: false,
      item: {
        uuid: "",
        name: "",
        shift: "",
        modality: "",
        course: "",
        max_students: "",
        date: "",
        start_time: "",
        end_time: "",
        display_panel: false,
        unit: "",
        status: { title: "Ativo", code: 1 },
        disciplines: "",
        postponements: "",
        weekdays: "",
        lessons: "",
      },
      filters: {
        disciplines: [],
        teachers: [],
        status: [],
        categories: [],
      },
      modalShow: false,
      optionsModalities: [],
      optionsCourses: [],
      optionsUnits: [],
      optionsTagTema: [],
      optionsShifts: [],
      optionsWorkDays: [],
      optionsTeachers: [],
      optionsGridLessonCategory: [],
      optionsMaterials: [],
      optionsGridLessonStatus: [],
      optionsGridTeachers: [],
      optionsGridStatus: [],
      optionsGridCategories: [],
      optionsLessonToGrids: [],
      lessons: [
        {
          date: "",
          start_time: "",
          end_time: "",
          teacher: "",
          category: "",
        },
      ],
      formEditLessons: [],
      lesson: {
        uuid: "",
        date: "",
        start_time: "",
        end_time: "",
        teacher: "",
        category: "",
        days: [],
        materials: [],
      },
      formLesson: {
        teacher: "",
        status: "",
        days: [],
        materials: [],
        category: "",
      },
      tableFields: [
        {
          key: "lessons",
          label: "Aulas",
          tdClass: "text-center",
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "title",
          label: "Disciplina",
          tdClass: "text-left",
          class: "text-left",
        },
        {
          key: "evaluate",
          label: "Avaliação",
          tdClass: "text-center",
          class: "text-left",
        },
        {
          key: "workload",
          label: "Previsto",
          tdClass: "text-center",
          class: "text-center",
        },
        {
          key: "category_1",
          label: "Normal",
          tdClass: "text-center",
          class: "text-center",
        },
        {
          key: "category_2",
          label: "Bônus",
          tdClass: "text-center",
          class: "text-center",
        },
        {
          key: "category_3",
          label: "Extra",
          tdClass: "text-center",
          class: "text-center",
        },
        {
          key: "total",
          label: "Total",
          tdClass: "text-center",
          class: "text-center",
          variant: 'primary'
        },
      ],
      lessonsExist: [],
      totalHours: 0,
      totalWorkload: 0,
      totalCategory1: 0,
      totalCategory2: 0,
      totalCategory3: 0,
      lessonDeleteMode: null,
      optionsModeDeleteLesson: [{ title: 'Somente desta grade', code: 1 }, { title: 'De todas as grades', code: 2 }],
    };
  },
  setup() {
    const {
      isCalendarOverlaySidebarActive,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventHandlerEditLesson,
    } = useCalendar();

    return {
      isCalendarOverlaySidebarActive,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventHandlerEditLesson,
    };
  },
  validations: {
    formEditLessons: {
      $each: {
        lessons: {
          $each: {
            teacher: {
              required,
            },
            category: {
              required
            },
            start_time: {
              required,
              minLength: minLength(5)
            },
            end_time: {
              required,
              minLength: minLength(5)
            }
          }
        }
      }
    },
    lessons: {
      $each: {
        date: {
          required,
        },
        start_time: {
          required,
          minLength: minLength(5)
        },
        end_time: {
          required,
          minLength: minLength(5)
        },
        teacher: {
          required,
        },
        category: {
          required,
        },
        video_code: {
          required: requiredIf((vm) => {
            return false;
          }),
        },
        status: {
          required,
        },
      },
    },
    lesson: {
      date: {
        required,
      },
      teacher: {
        required,
      },
      start_time: {
        required,
        minLength: minLength(5)
      },
      end_time: {
        required,
        minLength: minLength(5)
      },
      category: {
        required,
      },
      video_code: {
        required: requiredIf((vm) => {
          return vm.live;
        }),
      },
      status: {
        required,
      },
    },
    formImportLessons: {
      grid: {
        required,
      },
      disciplines: {
        required,
      },
    },
    formLesson: {
      teacher: {
        required,
      },
      status: {
        required,
      },
      days: {
        $each: {
          date: {
            required,
          },
          start: {
            required,
            minLength: minLength(5)
          },
          end: {
            required,
            minLength: minLength(5)
          },
          category: {
            required,
          },
        },
      },
    },
    formDeleteLessonsOfDisciplines: {
      required,
    },
    formLessonToGrid: {
      grid: {
        required: true,
      },
      lesson: {
        required: true,
      },
    }
  },
  methods: {
    // Método para converter tempo (HH:MM:SS) para minutos
    toMinutes(time) {
      if (!time || typeof time !== 'string' || !time.includes(':')) {
        // Retorna 0 se o tempo estiver em um formato inválido
        return 0;
      }

      const [hours, minutes, seconds] = time.split(':').map(Number);
      return (hours || 0) * 60 + (minutes || 0) + Math.floor((seconds || 0) / 60);
    },

    // Método para converter minutos de volta para o formato HH:MM
    toTimeString(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
    },
    compareHours(total, previsto, interval, quantityLessons) {
      let totalInMinutes = this.toMinutes(total);
      const previstoInMinutes = this.toMinutes(previsto);

      const intervalInMinutes = this.toMinutes(interval);
      const totalIntervalInMinutes = intervalInMinutes * quantityLessons;

      totalInMinutes = Math.max(0, totalInMinutes - totalIntervalInMinutes);

      const adjustedTotal = this.toTimeString(totalInMinutes);

      if (totalInMinutes < previstoInMinutes) {
        return {
          adjustedTotal,
          class: 'text-danger',
        };
      } else if (totalInMinutes === previstoInMinutes) {
        return {
          adjustedTotal,
          class: 'text-primary',
        };
      } else {
        return {
          adjustedTotal,
          class: 'text-success',
        };
      }
    },
    confirmDeleteLesson() {
      this.$swal({
        title: "Tem certeza?",
        text: "Uma vez a aula excluída, não será possível recuperá-la.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.submitDeleteLessons()
        }
      })
    },
    async submitDeleteLessons() {
      this.$swal({
        title: "Tem certeza?",
        text: `As aulas serão desvinculadas desta grade.`,
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.submittedDeleteLessons = true
          await this.$store.dispatch("Grid/deleteLessons", {
            uuid: this.item.uuid,
            lessons: this.formatDeleteLessons
          })
            .then(() => {
              this.formatDeleteLessons = []
              this.notifyDefault("success");
              this.getData();
              this.isModalGridLessonsDeleteActive = false;
              this.$bvModal.hide("modal-delete-lessons");
            })
            .finally(() => {
              this.submittedDeleteLessons = false
            });
        }
      })
    },
    async submitEditLessons() {
      this.$v.formEditLessons.$touch();

      if (!this.$v.formEditLessons.$error) {
        this.$swal({
          title: "Tem certeza?",
          text: `As aulas serão atualizadas em massa.`,
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Sim",
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-light ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            this.submittedEditLessons = true

            await this.$store.dispatch("Grid/updateLessons", { uuids: this.formEditLessons })
              .then(() => {
                this.formEditLessons = []
                this.notifyDefault("success");
                this.getData();
                this.isModalGridLessonsEditActive = false;
                this.$bvModal.hide("modal-edit-lessons");
              })
              .finally(() => {
                this.isModalGridLessonsEditActive = false
                this.submittedEditLessons = true
              });
          }
        })
      }
    },
    somarHorarios(horarios) {
      let duracaoTotal = moment.duration(0);

      horarios.forEach(horario => {
        let duracao = moment.duration(horario);
        duracaoTotal.add(duracao);
      });

      let horas = Math.floor(duracaoTotal.asHours());
      let minutos = duracaoTotal.minutes();

      return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}`;
    },
    lessonToGrid(uuid) {
      this.formLessonToGrid.lesson = uuid;
      this.$bvModal.show("modal-lesson-to-grid");
    },
    async fetchLessonToGrid(term) {
      if (term.length > 1) {
        this.optionsLessonToGrids = await this.$store.dispatch("Grid/search", term);
      }
    },
    async submitLessonToGrid() {
      this.$v.formLessonToGrid.$touch();

      if (!this.$v.formLessonToGrid.$error) {
        await this.$store.dispatch("Grid/lessonConnect", {
          grid: this.formLessonToGrid.grid.code,
          lesson: this.formLessonToGrid.lesson,
        })
          .then(() => {
            this.notifyDefault("success");
            this.getData();
            this.$bvModal.hide("modal-lesson-to-grid");
          });
      }
    },
    async addDay() {
      this.formLesson.days.push({
        date: '',
        number: this.formLesson.days.length + 1,
        start: '',
        end: '',
        category: this.optionsGridLessonCategory[0],
      })
    },
    async deleteLesson(i) {
      let allDays = await this.days;

      this.formLesson.days.splice(i, 1);

      this.days.splice(i, 1)

      this.formLesson.days.map((day, index) => {
        day.number = index + 1;
      })

      const datasISO = allDays
        .filter(data => data !== undefined)
        .map(data => moment(data, "DD/MM/YYYY").toISOString());

      function compararDatas(a, b) {
        const dataA = moment(a);
        const dataB = moment(b);
        if (dataA.isBefore(dataB)) {
          return -1;
        }
        if (dataA.isAfter(dataB)) {
          return 1;
        }
        return 0;
      }

      datasISO.sort(compararDatas);

      const datasOrdenadas = datasISO.map((dataISO) =>
        moment(dataISO).format("DD/MM/YYYY")
      );

      this.lesson.days = datasOrdenadas;

      await this.$store.dispatch("Grid/updateDays", datasOrdenadas);
    },
    resetFormLesson() {
      this.formLesson = {
        teacher: "",
        status: "",
        days: [],
        materials: [],
      };

      this.disciplineSelected = ""
    },
    formatTimer(time) {
      return moment(time, 'HH:mm:ss').format("HH:mm");
    },
    submitNewLesson() {
      this.$v.formLesson.$touch();

      if (!this.$v.formLesson.$error) {
        this.$store
          .dispatch("Grid/storeOnlyLesson", {
            classroom_uuid: this.classroom_uuid,
            discipline: this.disciplineSelected,
            lessons: this.formLesson,
            days: this.days,
            grid_uuid: this.item.uuid
          })
          .then(async (res) => {
            if (res.lessons) {
              this.lessonsExist = res.lessons
              this.$bvModal.show("modal-lessons-exists");
            } else {
              this.resetFormLesson()
              this.notifyDefault("success");
              this.getData();

              await this.$store.dispatch("Grid/resetDays");

              this.calendarOptions.clearDays()
              this.$v.formLesson.$reset()

              this.$bvModal.hide("modal-new-lesson");
            }
          })
          .catch((er) => {
            console.log(er)
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    submitLessonImports() {
      this.submitedImport = true;
      this.$v.formImportLessons.$touch();

      if (!this.$v.formImportLessons.$error) {
        this.$store
          .dispatch("Grid/storeImport", {
            uuid: this.item.uuid,
            grid_uuid: this.formImportLessons.grid.code,
            classroom_uuid: this.classroom_uuid,
            disciplines: this.formImportLessons.disciplines,
            date: this.formImportLessons.date
          })
          .then(() => {
            this.isModalGridLessonImportActive = false;
            this.notifyDefault("success");
            this.getData();
          })
          .catch(() => {
            this.submitedImport = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submitedImport = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    disciplinesSelected(event) {
      this.formImportLessons.disciplines = event.map(
        (discipline) => discipline.discipline_workload_uuid
      );
    },
    gridSelected(event) {
      this.optionsDisciplines = [];
      this.formImportLessons.disciplines = [];

      this.optionsDisciplines = event.disciplines;
      delete this.formImportLessons.grid.disciplines;
    },
    async fetchGrid(term) {
      if (term.length > 1) {
        this.optionsGrids = await this.$store.dispatch("Grid/search", term);
      }
    },
    removeLesson(mode = 1) {
      this.loading.lessonDelete = true;
      this.$store
        .dispatch("Grid/deleteLesson", {
          uuid: this.lesson.uuid,
          mode: mode,
          grid_uuid: this.item.uuid,
        })
        .then(() => {
          this.getData();
          this.$swal({
            icon: "success",
            title: mode ? "Aula excluída!" : "",
            text: mode ? "Aula foi excluída somente desta grade." : "A aula foi excluída de todas as grades.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });

          this.$nextTick(() => {
            this.$bvModal.hide("modal-lesson-delete");
          });

          return true
        })
        .finally(() => {
          this.loading.lessonDelete = false;
        });
    },
    optionDeleteSelected() {
      if (!this.lessonDeleteMode) return false
      this.removeLesson(this.lessonDeleteMode.code === 1 ? 1 : 2)
    },
    deleteLessonByDiscipline() {
      if (!this.formDeleteLessonsOfDisciplines.length){
        this.notify(
          "Selecione pelo menos 1 disciplina",
          "BellIcon",
          "warning",
          ""
        );

        return false;
      } else if (!this.lessonDeleteMode) {
        this.notify(
          "Selecione o modo de exclusão",
          "BellIcon",
          "warning",
          ""
        );

        return false
      } else {
        this.loading.lessonDelete = true;

        this.$store
          .dispatch("Grid/deleteLessonOfDisciplines", {
            uuid: this.item.uuid,
            mode: this.lessonDeleteMode.code,
            data: this.formDeleteLessonsOfDisciplines
          })
          .then(async () => {
            this.getData();
            this.formDeleteLessonsOfDisciplines = [];
            this.isModalGridLessonDisciplineDeleteActive = false;

            this.$swal({
              icon: "success",
              title: "Aulas excluídas!",
              text: this.lessonDeleteMode.code === 1 ? "Aulas excluídas somente desta grade." : "Aulas excluídas de todas as grades.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });

            this.$nextTick(() => {
              this.$bvModal.hide("modal-lesson-delete-discipline");
            });
          })
          .catch(() => {
            this.notifyDefault("error");
            this.loading.lessonDelete = false;
          })
          .finally(() => {
            this.submited = false;
            this.loading.lessonDelete = false;
          });
      }
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: `Atenção: após excluir, a aula não poderá ser recuperada!`,
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeLesson(1)
        }
      });
    },
    showFilters() {
      this.showAllFilters = this.showAllFilters ? false : true;
    },
    async changeDiscipline(value) {
      const _days = await this.$store.state.Grid.days;

      value.days = _days.length;
      await this.$store.dispatch("Grid/setDiscipline", value);
    },
    async updateDays() {
      if (!this.days.length > 0) return false;

      let datas = this.days;

      const datasISO = datas.map((data) => {

        moment(data, "DD/MM/YYYY").toISOString()
      }
      );

      function compararDatas(a, b) {
        const dataA = moment(a);
        const dataB = moment(b);
        if (dataA.isBefore(dataB)) {
          return -1;
        }
        if (dataA.isAfter(dataB)) {
          return 1;
        }
        return 0;
      }

      datasISO.sort(compararDatas);

      const datasOrdenadas = datasISO.map((dataISO) =>
        moment(dataISO).format("DD/MM/YYYY")
      );

      this.lesson.days = datasOrdenadas;

      await this.$store.dispatch("Grid/updateDays", datasOrdenadas);
    },
    async fetchMaterials(term) {
      if (term.length > 2) {
        this.optionsMaterials = await this.$store.dispatch(
          "Material/forSelectSearchTypeUnits",
          { term: term, type: 1 }
        );
      }
    },
    changeTimeLesson() { },
    changeTime(_index, _lesson) {
      const lesson = this.lesson;
      let duration = 0;

      const _lesson_startTime = moment(_lesson.start, "HH:mm");
      const _lesson_endTime = moment(_lesson.end, "HH:mm");

      const _lesson_minutes = _lesson_endTime.diff(
        _lesson_startTime,
        "minutes"
      );

      const _momentHour = moment
        .utc()
        .startOf("day")
        .add(_lesson_minutes, "minutes");

      const _workload = _momentHour.format("HH:mm");

      this.lesson.workload = _workload;

      lesson.days.forEach((element, index) => {
        if (element.start_time && element.end_time) {
          const moment1 = moment(element.start_time, "HH:mm");
          const moment2 = moment(element.end_time, "HH:mm");

          duration = duration + moment2.diff(moment1, "hours");
        }
      });

      let workload = `${this.disciplineSelected.workload > 9
        ? this.disciplineSelected.workload
        : "0" + this.disciplineSelected.workload
        }:00`;

      workload = moment(workload, "HH:mm").format("HH:mm");

      const hora1 = moment(workload, "HH:mm");
      const hora2 = moment(duration, "HH:mm");

      const diffHoras = hora1.diff(hora2, "hours");

      this.$store.dispatch("Grid/setDiscipline", {
        ...this.disciplineSelected,
        workloadCurrent: diffHoras,
      });
    },
    submitUpdateLesson() {
      this.$v.lesson.$touch();
      this.submited = true;

      if (!this.$v.lesson.$error) {
        this.$store
          .dispatch("Grid/updateLesson", {
            uuid: this.lesson.uuid,
            data: this.lesson,
          })
          .then(() => {
            this.$v.$reset;
            this.notifyDefault("success");
            this.closeModal();
            this.getData();
          })
          .catch(() => {
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$v.lessons.$touch();

      if (!this.$v.lessons.$error) {
        this.handleSubmit();
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async handleSubmit() {
      await this.$store
        .dispatch("Grid/store", { disciplineUuid: this.formLesson })
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-new-lesson");
          });
        });
    },
    closeModal() {
      this.$store.dispatch("Grid/resetEvent");
      this.$bvModal.hide("modal-new-lesson");
      this.isEventHandlerSidebarActive = false;
      this.isEventHandlerEditLesson = false;
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
        this.handleBackdrop();
      }
    },
    handleBackdrop() {
      this.isEventHandlerSidebarActive = false;
      this.isEventHandlerEditLesson = false;
    },
    async fetchTeachers() {
      this.optionsTeachers = [];
      this.optionsTeachers = await this.$store.dispatch(
        "Teacher/getByTagsSubjects",
        { disciplineUuid: this.disciplineSelected.code }
      );
    },

    async fetchTeachersByDiscipline(disciplineUuid) {
      this.optionsTeachersByDiscipline = [];
      this.optionsTeachersByDiscipline = await this.$store.dispatch(
        "Teacher/getByTagsSubjects",
        { disciplineUuid: disciplineUuid }
      );
    },
    async getData() {
      this.loading.page = true;
      this.loading.events = true;
      this.loading.disciplines = true;

      await this.$store
        .dispatch("Grid/show", this.class_uuid)
        .then(async (grid) => {

          this.item = grid;
          this.$store.dispatch("Grid/setUuid", this.item.uuid);
          this.$store.dispatch("Grid/fetchEventsOfGrid", {
            uuid: this.item.uuid,
            filters: this.filters,
          })
            .finally(() => {
              this.loading.events = false;
            });

          await this.$store.dispatch(
            "Grid/setDisciplines",
            this.item.disciplines
          )
            .finally(() => {
              this.loading.disciplines = false;
            });;

          this.optionsGridTeachers = this.item.filter_teachers;

          this.optionsGridStatus = this.item.filter_status;

          this.optionsGridCategories = this.item.filter_categories;

          if (this.item.postponements && this.item.postponements.length > 0) {
            await this.$store.dispatch("Grid/updateRangeDate", {
              start:
                this.item.postponements[this.item.postponements.length - 1]
                  .date,
              end: "",
            });
          }

          this.formEditLessons = this.item.disciplines
          this.tableDisciplines = this.item.disciplines;

          this.totalWorkload = this.somarHorarios(this.item.disciplines.map(disciplina => disciplina.workload));

          this.totalCategory1 = this.somarHorarios(this.item.disciplines.map(disciplina => disciplina.category_1));
          this.totalCategory2 = this.somarHorarios(this.item.disciplines.map(disciplina => disciplina.category_2));
          this.totalCategory3 = this.somarHorarios(this.item.disciplines.map(disciplina => disciplina.category_3));
        })
        .finally(() => {
          this.loading.page = false;
        });
    },
    checkField(field, item) {
      if (item.categories[field.key]) {
        return item.categories[field.key].workload;
      }
    },
    activeModalNewLesson() {
      this.isEventHandlerSidebarActive = this.isEventHandlerSidebarActive
        ? false
        : true;
    },
    openModalNewLesson() {
      this.formLesson.days = [];

      this.days.forEach((day, index) => {
        this.formLesson.days.push({
          date: day,
          number: index + 1,
          start: this.item.start_time,
          end: this.item.end_time,
          category: this.optionsGridLessonCategory[0],
        });
      });

      this.$root.$emit(`open:modal-new-lesson`, {
        status: true,
        elementName: 'modal-new-lesson',
      });
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    "b-popover": VBPopover,
    Ripple,
  },
  async mounted() {
    this.class_uuid = this.$route.params.uuid;
    this.optionsGridLessonStatus = await this.$store.dispatch(
      "Grid/forSelectLessonStatus"
    );

    this.optionsGridLessonCategory = await this.$store.dispatch(
      "Grid/forSelectLessonCategories"
    );

    this.$root.$on("bv::modal::hide", () => {
      this.isEventHandlerSidebarActive = false;
      this.isEventHandlerEditLesson = false;
      this.lessonDeleteMode = null;
    });

    this.calendarOptions.calendarLoaded = true;
    this.$root.$on("eventGridLessonNew", () => {
      window.location.reload();
    });

    this.$root.$on("eventGridLessonImport", () => {
      window.location.reload();
    });

    this.$root.$on("open:modal-new-lesson", () => {
      this.$bvModal.show("modal-new-lesson");
    })

    this.getData();
  },
  computed: {
    ...mapState("Grid", ["discipline", "disciplines", "days", "event"]),
  },
  watch: {
    // days: function (days) {
    //   this.formLesson.days = [];

    //   days.forEach((day, index) => {
    //     this.formLesson.days.push({
    //       date: day,
    //       number: index + 1,
    //       start: this.item.start_time,
    //       end: this.item.end_time,
    //       category: this.optionsGridLessonCategory[0],
    //       teacher: ''
    //     });
    //   });
    // },
    "filters.disciplines": function () {
      this.getData();
    },
    "filters.teachers": function () {
      this.getData();
    },
    "filters.status": function () {
      this.getData();
    },
    "filters.categories": function () {
      this.getData();
    },
    lessons: function () {
      console.log("watch lessons");
    },
    discipline: function (newValue) {
      console.log("wath discipline");
      this.disciplineSelected = newValue;
      this.fetchTeachers(this.disciplineSelected.code);
    },
    event: function (value) {
      this.lesson = value;
    },
  },
  beforeDestroy() {
    this.$root.$off("eventGridLessonNew");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-content {
  background: white;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.collapse-disciplines {
  .card-header {
    padding-top: 11px;
    padding-bottom: 11px;
    background: #f9f9f9;
    margin-bottom: 5px !important;

    .lead {
      font-size: 0.9rem;
    }
  }

  .card-body {
    padding: 0px;
    margin-top: 10px;
  }
}

.modal-new-lesson {
  .modal-dialog {
    max-width: 600px;
  }
}

.custom-checkbox {
  .custom-control-label {
    font-size: 0.9rem;
  }
}

.card-infos {
  .card {
    margin-bottom: 0px;

    .card-header {
      display: flex;
    }
  }
}

.fc-event {
  overflow: hidden !important;
}

.tabs-details-grid-lesson {
  ul {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 30px;
    padding-bottom: 15px;
  }
}
</style>
