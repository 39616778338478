<template>
  <div>
    <b-modal
      id="modal-new-lesson"
      centered
      title="Cadastrar aula "
      ok-only
      ok-title="Cadastrar"
      ok-variant="success"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @hide="onHide"
      :visible="isEventHandlerSidebarActive"
      hide-footer
    >
      <b-card-text>
        <form @submit.prevent="submitNewLesson">
          <div
            v-if="item.discipline"
            class="form-row justify-content-between"
          >
            <div class="col-md-8">
              <h4 class="text-primary" style="margin-bottom: 5px">
                {{ item.discipline.title }}
              </h4>
            </div>
            <div class="col-md-4 text-right">
              <span class="badge badge-dark float-right px-1">
                {{ item.discipline.workload }}h
              </span>
            </div>
          </div>

          <div class="d-block mb-2">
            <hr />
            <div class="form-row form-group">
              <label class="col-md-3 col-form-label">
                <i class="text-danger bi bi-record-circle"></i>
                Disciplina
              </label>
              <div class="col">
                <v-select
                  :disabled="!days.length"
                  :searchable="false"
                  label="title"
                  v-model="item.discipline"
                  item-text="title"
                  item-value="code"
                  placeholder="Digite o título"
                  :options="disciplines"
                  @input="changeDiscipline(item.discipline)"
                  :class="{
                    'is-invalid': $v.item.discipline.$error,
                  }"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="d-block">
              <div class="form-row form-group">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Professor
                </label>
                <div class="col">
                  <v-select
                    :searchable="false"
                    label="title"
                    v-model="item.teacher"
                    :class="{
                      'is-invalid': $v.item.teacher.$error,
                    }"
                    item-text="title"
                    item-value="code"
                    placeholder="Digite o título"
                    :options="optionsTeachers"
                  >
                    <span slot="no-options"> Nenhum registro encontrado </span>
                  </v-select>
                </div>
              </div>

              <div class="form-row form-group">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Categoria
                </label>
                <div class="col">
                  <v-select
                    :searchable="false"
                    label="title"
                    v-model="item.category"
                    :class="{
                      'is-invalid': $v.item.category.$error,
                    }"
                    item-text="title"
                    item-value="code"
                    placeholder="Digite o título"
                    :options="optionsGridLessonCategory"
                  >
                    <span slot="no-options"> Nenhum registro encontrado </span>
                  </v-select>
                </div>
              </div>

              <div class="form-row form-group mb-0">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Status
                </label>
                <div class="col">
                  <v-select
                    :searchable="false"
                    label="title"
                    v-model="item.status"
                    :class="{
                      'is-invalid': $v.item.status.$error,
                    }"
                    item-text="title"
                    item-value="code"
                    placeholder="Digite o título"
                    :options="optionsGridLessonStatus"
                  >
                    <span slot="no-options"> Nenhum registro encontrado </span>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <h5>
            Datas seleciondas
            <span class="badge badge-pill badge-primary float-right">
              {{ days.length }}
            </span>
          </h5>

          <hr />

          <div class="form-row">
            <div
              v-for="(day, index) in days"
              :key="index"
              class="col-md-12 col-12"
            >

              <div class="d-flex mb-1" style="align-content: center; justify-content: left; align-items: center; column-gap: 15px;">
                <span>
                  <i class="bi bi-pin" style="margin-right: 3px"></i>
                  {{ index + 1 }}/{{ days.length }}
                </span>

                <span>
                  <i
                    class="bi bi-calendar2-event"
                    style="margin-right: 3px"
                  ></i>
                </span>

                <span>
                  {{ day }}
                </span>

                <div class="d-flex" style="align-content: center; justify-content: center; align-items: center; column-gap: 3px;max-width: 170px;">
                  <flat-pickr
                      v-model="day.start"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                        enableSeconds: false,
                      }"
                  />
                  <span>
                    às
                  </span>
                  <flat-pickr
                      v-model="day.end"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                        enableSeconds: false,
                      }"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="form-row justify-content-end">
            <div class="col-md-3">
              <button
                @click="closeModal"
                type="button"
                class="btn btn-light btn-block"
              >
                Fechar
              </button>
            </div>
            <div class="col-md-3">
              <button :disabled="submited" type="submit" class="btn btn-success btn-block">
                <span v-if="submited">
                  <b-spinner small variant="light" />
                </span>
                <span>
                  Cadastrar
                </span>
              </button>
            </div>
          </div>
        </form>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BCardText, BModal, BSpinner } from "bootstrap-vue";

import { defineComponent } from "@vue/composition-api";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

import { mapState } from "vuex";

import moment from "moment";

export default defineComponent({
  name: 'GridLessonNew',
  data() {
    return {
      submited: false,
      item: {
        teacher: '',
        category: '',
        status: '',
        number: '',
        start: '',
        end: '',
        workload: '',
        days: [],
        discipline: '',
      },
      optionsGridLessonStatus: [],
      optionsGridLessonCategory: [],
      optionsTeachers: [],
    }
  },
  validations: {
    item: {
      discipline: {
        required,
      },
      start: {
        required,
      },
      end: {
        required,
      },
      teacher: {
        required,
      },
      category: {
        required,
      },
      status: {
        required,
      },
    },
  },
  props: {
    routeAction: {
      type: String,
      require: true,
    },
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
      default: false,
    },
    isEventHandlerEditLesson: {
      type: Boolean,
      require: false,
      default: false,
    },
    gridUuid: {
      type: String,
    },
    classUuid: {
      type: String,
    },
    startTime: {
      type: String,
      require: false,
    },
    endTime: {
      type: String,
      require: false,
    }
  },
  components: {
    flatPickr,
    BCard,
    BCardText,
    BModal,
    BSpinner,
    vSelect,
  },
  async mounted() {
    this.optionsGridLessonStatus = await this.$store.dispatch(
      "Grid/forSelectLessonStatus"
    );
    
    this.optionsGridLessonCategory = await this.$store.dispatch(
      "Grid/forSelectLessonCategories"
    );

    this.item.days = await this.$store.state.Grid.days;

    this.item.start = this.item.start_time;
    this.item.end = this.item.end_time;
  },
  methods: {
    changeTime() {
      let duration = 0;

      const start = moment(this.item.start, "HH:mm");
      const end = moment(this.item.end, "HH:mm");

      const minutes = end.diff(start, "minutes") * this.item.days.length;

      const hours = moment.utc().startOf("day").add(minutes, "minutes");
      const _workload = hours.format("HH:mm");

      this.item.workload = _workload;

      this.item.days.forEach((element, index) => {
        if (element.start && element.end) {
          const moment1 = moment(element.start, "HH:mm");
          const moment2 = moment(element.end, "HH:mm");
          duration = duration + moment2.diff(moment1, "hours");
        }
      });

      let workload = `${
        this.item.discipline.workload > 9
          ? this.item.discipline.workload
          : "0" + this.item.discipline.workload
      }:00`;

      workload = moment(workload, "HH:mm").format("HH:mm");

      const hora1 = moment(workload, "HH:mm");
      const hora2 = moment(duration, "HH:mm");
      const diffHoras = hora1.diff(hora2, "hours");

      this.$store.dispatch("Grid/setDiscipline", {
        ...this.item.discipline,
        workloadCurrent: diffHoras,
        days: this.days.length,
      });
    },
    async fetchTeachers() {
      this.optionsTeachers = [];
      this.optionsTeachers = await this.$store.dispatch(
        "Teacher/getByTagsSubjects",
        { disciplineUuid: this.item.discipline.code }
      );
    },
    async changeDiscipline(value) {
      this.item.teacher = '';

      this.fetchTeachers()
      
      const days = await this.$store.state.Grid.days;

      value.days = days.length;

      await this.$store.dispatch("Grid/setDiscipline", value);
      
    },
    closeModal() {
      this.$store.dispatch("Grid/resetEvent");
      this.$bvModal.hide("modal-new-lesson");
      this.isEventHandlerSidebarActive = false;
      this.isEventHandlerEditLesson = false;
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
        this.handleBackdrop();
      }
    },
    handleBackdrop() {
      this.isEventHandlerSidebarActive = false;
      this.isEventHandlerEditLesson = false;
    },
    submitNewLesson() {
      this.$v.item.$touch();

      if (!this.$v.item.$error) {
        this.$store
          .dispatch(`Grid/${this.routeAction}`, {
            classUuid: this.classUuid,
            gridUuid: this.gridUuid,
            lesson: this.item,            
          })
          .then(async (uuid) => {
            this.$v.item.$reset;

            await this.$store.dispatch("Grid/updateDays", []);
            await this.$store.dispatch("Grid/setDiscipline", '');

            this.notifyDefault("success");

            this.$bvModal.hide("modal-new-lesson");

            this.$root.$emit('eventGridLessonNew', true);

            this.item = {
              teacher: '',
              category: '',
              status: '',
              number: '',
              start: '',
              end: '',
              workload: '',
              days: [],
              discipline: '',
            }

            if (!this.gridUuid) {
              this.$router.push({ name: "grids-edit", params: uuid });
            }
          })
          .catch(() => {
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    totalTimeLesson(s, e) {
      const start = moment(s, "HH:mm");
      const end = moment(e, "HH:mm");

      const minutes = end.diff(start, "minutes");

      const hours = moment.utc().startOf("day").add(minutes, "minutes");
      return hours.format("HH:mm");
    }
  },
  computed: {
    ...mapState("Grid", ["discipline", "disciplines", "days", "lesson"]),
  },
  watch: {
    isEventHandlerSidebarActive: function () {
      this.item.days = this.days;
    },
    days: function (days) {
      console.log(days)
      this.item.days = [];

      days.forEach((day, index) => {
        this.item.days.push({
          date: day,
          number: index + 1,
          start: this.item.start_time,
          end: this.item.end_time,
        });
      });
    },
  }
});
</script>
