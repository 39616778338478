var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading.page && !_vm.item.uuid
        ? _c(
            "div",
            { staticClass: "py-5 text-center d-block" },
            [
              _c("b-spinner", {
                staticStyle: { "margin-right": "5px" },
                attrs: { small: "" },
              }),
              _vm._v(" carregando... "),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-card-actions",
                {
                  staticClass: "card-infos mb-1",
                  attrs: {
                    title: "Informações da turma",
                    "action-collapse": "",
                  },
                },
                [
                  _c("b-card-text", [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("strong", [_vm._v("Código: ")]),
                          _vm._v(" " + _vm._s(_vm.item.name) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-7" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("strong", [_vm._v("Título: ")]),
                          _vm._v(" " + _vm._s(_vm.item.course.title) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("strong", [_vm._v("Turno: ")]),
                          _vm._v(" " + _vm._s(_vm.item.shift.title) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("strong", [_vm._v("Modalidade:")]),
                          _vm._v(" " + _vm._s(_vm.item.modality.title) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("strong", [_vm._v("Horário:")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.item.start_time) +
                              " às " +
                              _vm._s(_vm.item.end_time) +
                              " "
                          ),
                        ]),
                      ]),
                      _vm.item.unit
                        ? _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("strong", [_vm._v("Unidade:")]),
                              _vm._v(" " + _vm._s(_vm.item.unit.title) + " "),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "col-md-2" }, [
                        this.item.postponements.length > 0
                          ? _c("div", { staticClass: "form-group" }, [
                              _c("strong", [_vm._v("Início:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("datePT")(
                                      _vm.item.postponements[
                                        _vm.item.postponements.length - 1
                                      ].date
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("div", { staticClass: "form-group mb-md-0" }, [
                          _c("strong", [_vm._v("Situação:")]),
                          _vm._v(" " + _vm._s(_vm.item.status.title) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("div", { staticClass: "form-group mb-md-0" }, [
                          _c("strong", [_vm._v("P. vendas:")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.item.display_panel ? "Sim" : "Não") +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-md-8" }, [
                        _c("div", { staticClass: "form-group mb-0 mb-md-0" }, [
                          _c(
                            "ul",
                            { staticClass: "list-inline mb-0" },
                            [
                              _c("li", [
                                _c("strong", { staticClass: "d-block mr-1" }, [
                                  _vm._v("Dias de aula: "),
                                ]),
                              ]),
                              _vm._l(
                                _vm.item.weekdays,
                                function (element, index) {
                                  return _c(
                                    "li",
                                    { key: index },
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticStyle: {
                                            "margin-right": "4px",
                                          },
                                          attrs: {
                                            pill: "",
                                            variant: "light-primary",
                                          },
                                        },
                                        [_vm._v(_vm._s(element.title))]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card mb-1" },
                [
                  _vm._m(0),
                  _vm.tableFields.length
                    ? _c(
                        "b-table-simple",
                        { staticClass: "mb-0", attrs: { responsive: "" } },
                        [
                          _vm.loading.disciplines
                            ? _c("div", { staticClass: "loading-overlay" }, [
                                _c(
                                  "div",
                                  { staticClass: "loading-content" },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "mr-1",
                                      attrs: { small: "", variant: "primary" },
                                    }),
                                    _vm._v(" Carregando... "),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "b-thead",
                            { attrs: { "head-variant": "dark" } },
                            [
                              _c(
                                "b-tr",
                                _vm._l(
                                  _vm.tableFields,
                                  function (field, index) {
                                    return _c(
                                      "b-th",
                                      { key: "t-" + index, class: field.class },
                                      [_vm._v(" " + _vm._s(field.label) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tbody",
                            _vm._l(
                              _vm.tableDisciplines,
                              function (discipline, index) {
                                return _c(
                                  "b-tr",
                                  { key: "b-tr-" + index },
                                  [
                                    _c(
                                      "b-td",
                                      { class: _vm.tableFields[0].class },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(discipline.lessons.length) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-td",
                                      { class: _vm.tableFields[1].class },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex-full mr-1",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-nowrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          discipline.title
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            discipline.dipe
                                              ? _c(
                                                  "b-badge",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center justify-content",
                                                    staticStyle: {
                                                      "column-gap": "5px",
                                                    },
                                                    attrs: {
                                                      variant: "danger",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          viewBox: "0 0 24 24",
                                                          width: "16",
                                                          height: "16",
                                                          color: "#ffffff",
                                                          fill: "none",
                                                        },
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                          },
                                                        }),
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M11.992 16H12.001",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        }),
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M12 13L12 8.99997",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _vm._v("DIPE"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-td",
                                      {
                                        staticClass: "text-center",
                                        class: _vm.tableFields[2].class,
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "bi bi-star-fill",
                                          staticStyle: {
                                            color: "#00bb76",
                                            "font-size": "0.7rem",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              discipline.evaluate.total.toFixed(
                                                2
                                              )
                                            ) +
                                            " (" +
                                            _vm._s(
                                              discipline.evaluate.quantity
                                            ) +
                                            ") "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-td",
                                      { class: _vm.tableFields[3].class },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-nowrap" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(discipline.workload) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-td",
                                      { class: _vm.tableFields[4].class },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              discipline.category_1
                                                ? discipline.category_1
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-td",
                                      { class: _vm.tableFields[5].class },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              discipline.category_2
                                                ? discipline.category_2
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-td",
                                      { class: _vm.tableFields[6].class },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              discipline.category_3
                                                ? discipline.category_3
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-td",
                                      {
                                        staticClass: "text-center",
                                        class: _vm.compareHours(
                                          discipline.total,
                                          discipline.workload,
                                          _vm.item.interval,
                                          discipline.lessons.length
                                        ).class,
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.compareHours(
                                                discipline.total,
                                                discipline.workload,
                                                _vm.item.interval,
                                                discipline.lessons.length
                                              ).adjustedTotal
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                          _c("b-tfoot", [
                            _c("tr", [
                              _c("td", { attrs: { colspan: "3" } }),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.totalWorkload)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.totalCategory1)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.totalCategory2)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.totalCategory3)),
                              ]),
                              _c("td"),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "mb-1",
                        class: { show: _vm.isCalendarOverlaySidebarActive },
                      },
                      [
                        _c("h5", { staticClass: "text-purple" }, [
                          _c("strong", [_vm._v("Datas seleciondas")]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-pill badge-primary float-right",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$store.state.Grid.days.length) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c("hr"),
                        _vm._l(
                          _vm.$store.state.Grid.days,
                          function (day, index) {
                            return _c(
                              "small",
                              {
                                key: index,
                                staticClass: "d-block",
                                class:
                                  index !==
                                  _vm.$store.state.Grid.days.length - 1
                                    ? "mb-1"
                                    : "",
                              },
                              [
                                _c("i", {
                                  staticClass: "bi bi-calendar2-event",
                                  staticStyle: { "margin-right": "3px" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(index + 1) +
                                    "/" +
                                    _vm._s(_vm.$store.state.Grid.days.length) +
                                    " - " +
                                    _vm._s(day) +
                                    " "
                                ),
                              ]
                            )
                          }
                        ),
                        !_vm.$store.state.Grid.days.length
                          ? _c("p", { staticClass: "mb-0" }, [
                              _vm._v(" Nenhuma data selecionada. "),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c("b-card", [
                      _c("h5", { staticClass: "app-label" }, [
                        _vm._v("Filtrar aulas"),
                      ]),
                      _c("hr"),
                      _c("p", { staticClass: "mb-1" }, [
                        _vm._v(
                          " Selecione os filtros abaixo para filtrar as aulas no calendário "
                        ),
                      ]),
                      _vm.optionsGridTeachers.length
                        ? _c(
                            "div",
                            { staticClass: "form-group mb-2 mt-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "mb-1", attrs: { for: "" } },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-caret-right-fill",
                                  }),
                                  _vm._v(" Professores "),
                                ]
                              ),
                              _c(
                                "b-form-checkbox-group",
                                { attrs: { name: "ck-teachers", stacked: "" } },
                                _vm._l(
                                  _vm.optionsGridTeachers,
                                  function (element, index) {
                                    return _c(
                                      "b-form-checkbox",
                                      {
                                        key: index,
                                        staticClass: "cursor-pointer",
                                        staticStyle: { "margin-bottom": "8px" },
                                        attrs: {
                                          id: "ck-teacher-" + index,
                                          name: "teacher-" + index,
                                          value: element.code,
                                        },
                                        model: {
                                          value: _vm.filters.teachers,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "teachers",
                                              $$v
                                            )
                                          },
                                          expression: "filters.teachers",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(element.title) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.item.disciplines.length
                        ? _c(
                            "div",
                            { staticClass: "form-group mb-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "mb-1", attrs: { for: "" } },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-caret-right-fill",
                                  }),
                                  _vm._v(" Disciplinas "),
                                ]
                              ),
                              _c(
                                "b-form-checkbox-group",
                                {
                                  attrs: { name: "ck-discipline", stacked: "" },
                                },
                                _vm._l(
                                  _vm.item.disciplines,
                                  function (element, index) {
                                    return _c(
                                      "b-form-checkbox",
                                      {
                                        key: index,
                                        staticClass: "cursor-pointer",
                                        staticStyle: { "margin-bottom": "8px" },
                                        attrs: {
                                          id: "ck-discipline-" + index,
                                          name: "ck-n-discipline-" + index,
                                          value: element.code,
                                        },
                                        model: {
                                          value: _vm.filters.disciplines,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "disciplines",
                                              $$v
                                            )
                                          },
                                          expression: "filters.disciplines",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(element.title) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.optionsGridStatus.length
                        ? _c(
                            "div",
                            { staticClass: "form-group mb-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "mb-1", attrs: { for: "" } },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-caret-right-fill",
                                  }),
                                  _vm._v(" Status "),
                                ]
                              ),
                              _c(
                                "b-form-checkbox-group",
                                { attrs: { name: "ck-status", stacked: "" } },
                                _vm._l(
                                  _vm.optionsGridStatus,
                                  function (element, index) {
                                    return _c(
                                      "b-form-checkbox",
                                      {
                                        key: index,
                                        staticClass: "cursor-pointer",
                                        staticStyle: { "margin-bottom": "8px" },
                                        attrs: {
                                          id: "ck-status-" + index,
                                          name: "status-" + index,
                                          value: element.code,
                                        },
                                        model: {
                                          value: _vm.filters.status,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.filters, "status", $$v)
                                          },
                                          expression: "filters.status",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { class: "text-" + element.color },
                                          [
                                            _vm._v(
                                              " " + _vm._s(element.title) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.item.categories.length
                        ? _c(
                            "div",
                            { staticClass: "form-group mb-0" },
                            [
                              _c(
                                "label",
                                { staticClass: "mb-1", attrs: { for: "" } },
                                [
                                  _c("i", {
                                    staticClass: "bi bi-caret-right-fill",
                                  }),
                                  _vm._v(" Categorias "),
                                ]
                              ),
                              _c(
                                "b-form-checkbox-group",
                                {
                                  attrs: { name: "ck-categories", stacked: "" },
                                },
                                _vm._l(
                                  _vm.item.categories,
                                  function (element, index) {
                                    return _c(
                                      "b-form-checkbox",
                                      {
                                        key: index,
                                        staticClass: "cursor-pointer",
                                        staticStyle: { "margin-bottom": "8px" },
                                        attrs: {
                                          id: "ck-category-" + index,
                                          name: "category-" + index,
                                          value: element.code,
                                        },
                                        model: {
                                          value: _vm.filters.categories,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "categories",
                                              $$v
                                            )
                                          },
                                          expression: "filters.categories",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(element.title) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-8" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "app-calendar calendar-grid overflow-hidden border",
                    },
                    [
                      _c("div", { staticClass: "row no-gutters" }, [
                        _c("div", { staticClass: "col position-relative" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions",
                            },
                            [
                              _c("div", { staticClass: "card-header" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-row justify-content-between",
                                  },
                                  [
                                    _c("div", { staticClass: "col-md-10" }, [
                                      !this.$store.state.Grid.days.length
                                        ? _c(
                                            "p",
                                            {
                                              staticClass: "mb-0 font-medium-1",
                                            },
                                            [
                                              _vm._v(
                                                " Selecione pelo menos 1 data no calendário baixo, caso queira "
                                              ),
                                              _c(
                                                "strong",
                                                { staticClass: "text-black" },
                                                [_vm._v("criar(não importar)")]
                                              ),
                                              _vm._v(" uma aula. "),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-2" },
                                      [
                                        _c(
                                          "b-dropdown",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: {
                                              text: "Opções",
                                              variant: "primary",
                                              right: "",
                                              block: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isModalGridLessonImportActive = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-arrow-down-up",
                                                  staticStyle: {
                                                    "margin-right": "3px",
                                                  },
                                                }),
                                                _vm._v(" Importar grade "),
                                              ]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isModalGridLessonDisciplineDeleteActive = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-trash text-danger",
                                                  staticStyle: {
                                                    "margin-right": "3px",
                                                  },
                                                }),
                                                _vm._v(
                                                  " Deletar por disciplinas "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isModalGridLessonsDeleteActive = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-trash text-danger",
                                                  staticStyle: {
                                                    "margin-right": "3px",
                                                  },
                                                }),
                                                _vm._v(" Deletar aulas "),
                                              ]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isModalGridLessonsEditActive = true
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-pencil-square",
                                                  staticStyle: {
                                                    "margin-right": "3px",
                                                  },
                                                }),
                                                _vm._v(" Editar aulas "),
                                              ]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                attrs: {
                                                  disabled:
                                                    !this.$store.state.Grid.days
                                                      .length,
                                                },
                                                on: {
                                                  click: _vm.openModalNewLesson,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "bi bi-plus-lg",
                                                  staticStyle: {
                                                    "margin-right": "3px",
                                                  },
                                                }),
                                                _vm._v(" Criar aula "),
                                              ]
                                            ),
                                            _c("b-dropdown-item", [
                                              _c("i", {
                                                staticClass: "bi bi-send",
                                                staticStyle: {
                                                  "margin-right": "3px",
                                                },
                                              }),
                                              _vm._v(" Cobrar professor "),
                                            ]),
                                            _c("b-dropdown-divider"),
                                            _c(
                                              "b-dropdown-item",
                                              { staticClass: "text-danger" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-trash text-danger",
                                                  staticStyle: {
                                                    "margin-right": "3px",
                                                  },
                                                }),
                                                _vm._v(" Encerrar turma "),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "card-body pb-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "calendar-wrapper position-relative",
                                  },
                                  [
                                    _vm.loading.events
                                      ? _c(
                                          "div",
                                          { staticClass: "loading-overlay" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "loading-content",
                                              },
                                              [
                                                _c("b-spinner", {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    small: "",
                                                    variant: "primary",
                                                  },
                                                }),
                                                _vm._v(
                                                  " Carregando eventos... "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("full-calendar", {
                                      ref: "refCalendar",
                                      staticClass: "full-calendar",
                                      attrs: { options: _vm.calendarOptions },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "eventContent",
                                          fn: function (arg) {
                                            return [
                                              _c("span", {
                                                attrs: {
                                                  id:
                                                    "popover-id-" +
                                                    arg.event.id,
                                                  title: arg.event.title,
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    arg.event.title
                                                  ),
                                                },
                                              }),
                                              _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    target:
                                                      "popover-id-" +
                                                      arg.event.id,
                                                    variant:
                                                      arg.event.extendedProps
                                                        .color,
                                                    triggers: "hover",
                                                    placement: "top",
                                                    title: arg.event.title,
                                                    "custom-class":
                                                      "fc-popover",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "title",
                                                        fn: function () {
                                                          return [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    arg.event
                                                                      .title
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("p", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        arg.event.extendedProps
                                                          .description
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-edit-lesson",
            centered: "",
            title: "Alterar aula",
            "ok-only": "",
            "ok-title": "Salvar",
            "ok-variant": "success",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            visible: _vm.isEventHandlerEditLesson,
            "hide-footer": "",
          },
          on: { hide: _vm.onHide },
        },
        [
          _vm.lesson.uuid
            ? _c(
                "b-card-text",
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submitUpdateLesson.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "b-tabs",
                        {
                          staticClass: "tabs-details-grid-lesson",
                          attrs: { pills: "" },
                        },
                        [
                          _c(
                            "b-tab",
                            {
                              attrs: { active: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "d-none d-sm-inline" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "text-danger bi bi-record-circle",
                                            }),
                                            _vm._v(" Detalhes da aula "),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1125283584
                              ),
                            },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass: "text-primary mb-1",
                                  staticStyle: { "font-size": "1.1rem" },
                                },
                                [
                                  _c("i", { staticClass: "bi bi-hash" }),
                                  _vm._v(
                                    _vm._s(_vm.lesson.id) +
                                      " - " +
                                      _vm._s(_vm.lesson.discipline.title) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("hr"),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-row justify-content-between",
                                },
                                [
                                  _c("div", { staticClass: "col-md-8 col-6" }, [
                                    _c("small", [
                                      _c("i", {
                                        staticClass: "bi bi-calendar2-event",
                                        staticStyle: { "margin-right": "3px" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("datePT")(_vm.lesson.date)
                                          ) +
                                          " "
                                      ),
                                      _c("i", {
                                        staticClass: "bi bi-hourglass-top ml-1",
                                        staticStyle: { "margin-right": "3px" },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("justTime")(
                                              _vm.lesson.workload
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-md-4 col-6 text-right",
                                    },
                                    [
                                      _c("small", [
                                        _c("i", {
                                          staticClass: "bi bi-pin",
                                          staticStyle: {
                                            "margin-right": "3px",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.lesson.number) +
                                            " de " +
                                            _vm._s(
                                              _vm.lesson.discipline.lessons
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c("hr"),
                              _c(
                                "div",
                                { staticClass: "form-row form-group" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "col-md-3 col-form-label" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Horário "),
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c("div", { staticClass: "col-md-5" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: "##:##",
                                              expression: "'##:##'",
                                            },
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.lesson.start_time,
                                              expression: "lesson.start_time",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.$v.lesson.start_time.$error,
                                          },
                                          attrs: { type: "tel" },
                                          domProps: {
                                            value: _vm.lesson.start_time,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.lesson,
                                                "start_time",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-2 text-center" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "d-block",
                                              staticStyle: {
                                                "line-height": "3",
                                              },
                                            },
                                            [_vm._v(" às ")]
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "col-md-5" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.lesson.end_time,
                                              expression: "lesson.end_time",
                                            },
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: "##:##",
                                              expression: "'##:##'",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.$v.lesson.end_time.$error,
                                          },
                                          attrs: { type: "tel" },
                                          domProps: {
                                            value: _vm.lesson.end_time,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.lesson,
                                                "end_time",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-3" },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass:
                                            "custom-control-mode-lesson custom-control-primary",
                                          attrs: {
                                            checked: "true",
                                            name: "check-button",
                                            switch: "",
                                          },
                                          model: {
                                            value: _vm.lesson.live,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.lesson, "live", $$v)
                                            },
                                            expression: "lesson.live",
                                          },
                                        },
                                        [_vm._v(" Live ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-row form-group" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "col-md-3 col-form-label" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Professor "),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c(
                                        "v-select",
                                        {
                                          class: {
                                            "is-invalid":
                                              _vm.$v.lesson.teacher.$error,
                                          },
                                          attrs: {
                                            searchable: true,
                                            label: "title",
                                            "item-text": "title",
                                            "item-value": "code",
                                            placeholder: "Digite o título",
                                            options: _vm.optionsTeachers,
                                          },
                                          model: {
                                            value: _vm.lesson.teacher,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.lesson,
                                                "teacher",
                                                $$v
                                              )
                                            },
                                            expression: "lesson.teacher",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "no-options" },
                                              slot: "no-options",
                                            },
                                            [
                                              _vm._v(
                                                " Nenhum registro encontrado "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-row form-group" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "col-md-3 col-form-label" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Categoria "),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c(
                                        "v-select",
                                        {
                                          class: {
                                            "is-invalid":
                                              _vm.$v.lesson.category.$error,
                                          },
                                          attrs: {
                                            searchable: false,
                                            label: "title",
                                            "item-text": "title",
                                            "item-value": "code",
                                            placeholder: "Digite o título",
                                            options:
                                              _vm.optionsGridLessonCategory,
                                          },
                                          model: {
                                            value: _vm.lesson.category,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.lesson,
                                                "category",
                                                $$v
                                              )
                                            },
                                            expression: "lesson.category",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "no-options" },
                                              slot: "no-options",
                                            },
                                            [
                                              _vm._v(
                                                " Nenhum registro encontrado "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-row form-group" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "col-md-3 col-form-label" },
                                    [_vm._v(" Materiais ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c(
                                        "v-select",
                                        {
                                          attrs: {
                                            multiple: "",
                                            label: "title",
                                            "item-text": "title",
                                            "item-value": "code",
                                            placeholder:
                                              "Digite o título do material",
                                            options: _vm.optionsMaterials,
                                          },
                                          on: { search: _vm.fetchMaterials },
                                          model: {
                                            value: _vm.lesson.materials,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.lesson,
                                                "materials",
                                                $$v
                                              )
                                            },
                                            expression: "lesson.materials",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "no-options" },
                                              slot: "no-options",
                                            },
                                            [
                                              _vm._v(
                                                " Nenhum registro encontrado "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-row form-group" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "col-md-3 col-form-label" },
                                    [
                                      _vm.lesson.live
                                        ? _c("i", {
                                            staticClass:
                                              "text-danger bi bi-record-circle",
                                          })
                                        : _vm._e(),
                                      _vm._v(" Cód. vídeo "),
                                    ]
                                  ),
                                  _c("div", { staticClass: "col" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.lesson.video_code,
                                          expression: "lesson.video_code",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.lesson.video_code.$error,
                                      },
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.lesson.video_code,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.lesson,
                                            "video_code",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-row form-group mb-0" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "col-md-3 col-form-label" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Status "),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c(
                                        "v-select",
                                        {
                                          class: {
                                            "is-invalid":
                                              _vm.$v.lesson.status.$error,
                                          },
                                          attrs: {
                                            searchable: false,
                                            label: "title",
                                            "item-text": "title",
                                            "item-value": "code",
                                            placeholder: "Digite o título",
                                            options:
                                              _vm.optionsGridLessonStatus,
                                          },
                                          model: {
                                            value: _vm.lesson.status,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.lesson,
                                                "status",
                                                $$v
                                              )
                                            },
                                            expression: "lesson.status",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "no-options" },
                                              slot: "no-options",
                                            },
                                            [
                                              _vm._v(
                                                " Nenhum registro encontrado "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "b-tab",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "d-none d-sm-inline" },
                                          [
                                            _vm._v(" Grades "),
                                            _vm.lesson.grids &&
                                            _vm.lesson.grids.length > 1
                                              ? _c("b-badge", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.lesson.grids.length
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3511568514
                              ),
                            },
                            [
                              _c("div", { staticClass: "form-row mb-2" }, [
                                _c("div", { staticClass: "col-md-10" }, [
                                  _vm._v(
                                    " Você também pode vincular a aula a outra grade. "
                                  ),
                                ]),
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-primary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.lessonToGrid(
                                            _vm.lesson.uuid
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "cursor-pointer cursor",
                                        attrs: { icon: "PlusIcon", size: "16" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm.lesson.grids.length
                                ? _c("b-table", {
                                    staticClass: "position-relative",
                                    attrs: {
                                      "sticky-header": "",
                                      items: _vm.lesson.grids,
                                      responsive: "",
                                      fields: _vm.fieldsGrids,
                                      "primary-key": "id",
                                      "show-empty": "",
                                      "empty-text":
                                        "Nenhum registro encontrado",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(uuid)",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.name) +
                                                  " - " +
                                                  _vm._s(item.course_title) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(action)",
                                          fn: function (data) {
                                            return [
                                              _vm.item.uuid !== data.item.uuid
                                                ? _c("feather-icon", {
                                                    staticClass:
                                                      "cursor-pointer cursor",
                                                    attrs: {
                                                      icon: "EditIcon",
                                                      size: "16",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$router.push(
                                                          {
                                                            name: "grids-edit",
                                                            params: {
                                                              uuid: data.item
                                                                .uuid,
                                                            },
                                                          }
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1986307665
                                    ),
                                  })
                                : _c("p", [
                                    _vm._v(
                                      " Esta aula não está vinculada a nenhuma outra grade até o momento. "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("hr"),
                      _c(
                        "div",
                        { staticClass: "form-row justify-content-between" },
                        [
                          _c("div", { staticClass: "col-md-2" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger btn-block",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.lesson.grids.length > 1
                                      ? _vm.$bvModal.show("modal-lesson-delete")
                                      : _vm.confirmDelete()
                                  },
                                },
                              },
                              [_c("i", { staticClass: "bi bi-trash3" })]
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-3 offset-md-4" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-light btn-block",
                                attrs: { type: "button" },
                                on: { click: _vm.closeModal },
                              },
                              [_vm._v(" Fechar ")]
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success btn-block",
                                attrs: { type: "submit" },
                              },
                              [_vm._v(" Salvar ")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : !_vm.lesson.uuid
            ? _c(
                "div",
                { staticClass: "d-block text-center py-3" },
                [
                  _c("b-spinner", {
                    staticStyle: { "margin-right": "5px" },
                    attrs: { small: "" },
                  }),
                  _vm._v(" carregando... "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-new-lesson",
            centered: "",
            title: "Cadastrar aula ",
            "ok-only": "",
            "ok-title": "Cadastrar",
            "ok-variant": "success",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            visible: _vm.isEventHandlerSidebarActive,
            "hide-footer": "",
            "modal-class": "modal-new-lesson",
          },
          on: { hide: _vm.onHide },
        },
        [
          _c(
            "b-card-text",
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitNewLesson.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.disciplineSelected
                    ? _c(
                        "div",
                        { staticClass: "form-row justify-content-between" },
                        [
                          _c("div", { staticClass: "col-md-8" }, [
                            _c(
                              "h4",
                              {
                                staticClass: "text-primary",
                                staticStyle: { "margin-bottom": "5px" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.disciplineSelected.title) +
                                    " "
                                ),
                              ]
                            ),
                            _c("strong", { staticClass: "d-block" }, [
                              _vm._v(
                                " Aulas: " +
                                  _vm._s(_vm.formLesson.days.length) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-4 text-right" }, [
                            _c(
                              "span",
                              {
                                staticClass: "d-block",
                                staticStyle: { "margin-bottom": "5px" },
                              },
                              [_vm._v("Carga horária ")]
                            ),
                            _c("strong", { staticClass: "d-block" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.disciplineSelected.workload) +
                                  "h "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "d-block mb-2" }, [
                    _vm.disciplineSelected ? _c("hr") : _vm._e(),
                    _c("div", { staticClass: "form-row form-group" }, [
                      _c("label", { staticClass: "col-md-3 col-form-label" }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Disciplina "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "v-select",
                            {
                              attrs: {
                                disabled: !_vm.days.length,
                                searchable: false,
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título",
                                options: _vm.item.disciplines,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changeDiscipline(
                                    _vm.disciplineSelected
                                  )
                                },
                              },
                              model: {
                                value: _vm.disciplineSelected,
                                callback: function ($$v) {
                                  _vm.disciplineSelected = $$v
                                },
                                expression: "disciplineSelected",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "form-row form-group" }, [
                      _c("label", { staticClass: "col-md-3 col-form-label" }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Professor "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "v-select",
                            {
                              class: {
                                "is-invalid": _vm.$v.formLesson.teacher.$error,
                              },
                              attrs: {
                                searchable: true,
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsTeachers,
                              },
                              model: {
                                value: _vm.formLesson.teacher,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formLesson, "teacher", $$v)
                                },
                                expression: "formLesson.teacher",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "d-block" }, [
                      _c("div", { staticClass: "form-row form-group" }, [
                        _c(
                          "label",
                          { staticClass: "col-md-3 col-form-label" },
                          [_vm._v(" Materiais ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "v-select",
                              {
                                attrs: {
                                  multiple: "",
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o título do material",
                                  options: _vm.optionsMaterials,
                                },
                                on: { search: _vm.fetchMaterials },
                                model: {
                                  value: _vm.formLesson.materials,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formLesson, "materials", $$v)
                                  },
                                  expression: "formLesson.materials",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-row form-group mb-0" }, [
                        _c(
                          "label",
                          { staticClass: "col-md-3 col-form-label" },
                          [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Status "),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid": _vm.$v.formLesson.status.$error,
                                },
                                attrs: {
                                  searchable: false,
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o título",
                                  options: _vm.optionsGridLessonStatus,
                                },
                                model: {
                                  value: _vm.formLesson.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formLesson, "status", $$v)
                                  },
                                  expression: "formLesson.status",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-11" }, [
                      _c("h5", { staticClass: "mb-0" }, [
                        _vm._v(" Datas selecionadas: "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.formLesson.days.length)),
                        ]),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: { "margin-top": "5px" },
                        },
                        [
                          _vm._v(
                            " Ao preencher o horário da aula, será verificado se existe aula no mesmo dia e horário. "
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-block btn-primary",
                          staticStyle: {
                            padding: "0px 3px !important",
                            height: "63px",
                          },
                          attrs: { type: "button" },
                          on: { click: _vm.addDay },
                        },
                        [_c("i", { staticClass: "bi bi-plus" })]
                      ),
                    ]),
                  ]),
                  _c("hr"),
                  _vm._l(_vm.formLesson.days, function (day, index) {
                    return _vm.formLesson.days.length
                      ? _c(
                          "div",
                          {
                            key: index,
                            staticClass: "form-row mb-1",
                            staticStyle: { "align-items": "center" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-1 text-center",
                                staticStyle: {
                                  "align-content": "center",
                                  "justify-content": "left",
                                  "align-items": "center",
                                  "column-gap": "20px",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formLesson.days[index].number) +
                                    "/" +
                                    _vm._s(_vm.days.length) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("flat-pickr", {
                                  staticClass: "form-control",
                                  attrs: {
                                    config: {
                                      altInput: true,
                                      dateFormat: "d/m/Y",
                                      altFormat: "d/m/Y",
                                      locale: "pt",
                                    },
                                  },
                                  model: {
                                    value: day.date,
                                    callback: function ($$v) {
                                      _vm.$set(day, "date", $$v)
                                    },
                                    expression: "day.date",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-4" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: day.start,
                                        expression: "day.start",
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["##:##"],
                                        expression: "['##:##']",
                                      },
                                    ],
                                    staticClass: "form-control mr-1",
                                    class: {
                                      "is-invalid":
                                        _vm.$v.formLesson.days.$each[index]
                                          .start.$error,
                                    },
                                    attrs: {
                                      type: "tel",
                                      placeholder: "H. Início",
                                    },
                                    domProps: { value: day.start },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          day,
                                          "start",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: day.end,
                                        expression: "day.end",
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["##:##"],
                                        expression: "['##:##']",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid":
                                        _vm.$v.formLesson.days.$each[index].end
                                          .$error,
                                    },
                                    attrs: {
                                      type: "tel",
                                      placeholder: "H. Fim",
                                    },
                                    domProps: { value: day.end },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          day,
                                          "end",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.formLesson.days.$each[index]
                                          .category.$error,
                                    },
                                    attrs: {
                                      searchable: false,
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Categoria",
                                      options: _vm.optionsGridLessonCategory,
                                    },
                                    model: {
                                      value: day.category,
                                      callback: function ($$v) {
                                        _vm.$set(day, "category", $$v)
                                      },
                                      expression: "day.category",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-1" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-block btn-danger btn-sm",
                                  staticStyle: {
                                    height: "44px",
                                    padding: "0px !important",
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteLesson(index)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "bi bi-trash" })]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e()
                  }),
                  _c("hr"),
                  _c("div", { staticClass: "form-row justify-content-end" }, [
                    _c("div", { staticClass: "col-md-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light btn-block",
                          attrs: { type: "button" },
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v(" Fechar ")]
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success btn-block",
                          attrs: { type: "submit" },
                        },
                        [_vm._v(" Cadastrar ")]
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-delete-import-grid",
            centered: "",
            title: "Deletar aulas por disciplinas ",
            "ok-only": "",
            "ok-title": "Deletar",
            "ok-variant": "success",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            visible: _vm.isModalGridLessonDisciplineDeleteActive,
            "hide-footer": "",
          },
          on: { hide: _vm.onHide },
        },
        [
          _c(
            "b-card-text",
            [
              _c("p", [
                _vm._v(
                  "Serão removidas da grade, todas as aulas das disciplinas selecionadas."
                ),
              ]),
              _vm._l(_vm.item.disciplines, function (discipline, index) {
                return _c("b-list-group-item", { key: index }, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("b-form-checkbox", {
                        staticClass: "custom-control-primary",
                        attrs: {
                          checked: true,
                          value: discipline.code,
                          name: "discipline-del-" + index,
                          id: "discipline-del-" + index,
                          switch: "",
                        },
                        model: {
                          value: _vm.formDeleteLessonsOfDisciplines,
                          callback: function ($$v) {
                            _vm.formDeleteLessonsOfDisciplines = $$v
                          },
                          expression: "formDeleteLessonsOfDisciplines",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content",
                        },
                        [
                          _c("div", { staticClass: "d-flex-full mr-1" }, [
                            _c("span", { staticClass: "text-nowrap" }, [
                              _vm._v(
                                " " +
                                  _vm._s(discipline.title) +
                                  " (" +
                                  _vm._s(discipline.lessons.length) +
                                  ") "
                              ),
                            ]),
                          ]),
                          discipline.dipe
                            ? _c(
                                "b-badge",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content",
                                  staticStyle: { "column-gap": "5px" },
                                  attrs: { variant: "danger" },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 24 24",
                                        width: "16",
                                        height: "16",
                                        color: "#ffffff",
                                        fill: "none",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z",
                                          stroke: "currentColor",
                                          "stroke-width": "2",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d: "M11.992 16H12.001",
                                          stroke: "currentColor",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d: "M12 13L12 8.99997",
                                          stroke: "currentColor",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("span", [_vm._v("DIPE")]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              _c("hr"),
              _c("div", { staticClass: "form-row justify-content-end" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light btn-block",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.isModalGridLessonDisciplineDeleteActive = false
                        },
                      },
                    },
                    [_vm._v(" Fechar ")]
                  ),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger btn-block",
                      attrs: { disabled: _vm.submitedImport, type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.show(
                            "modal-lesson-delete-discipline"
                          )
                        },
                      },
                    },
                    [
                      _vm.submitedImport
                        ? _c(
                            "span",
                            [
                              _c("b-spinner", {
                                attrs: { small: "", variant: "light" },
                              }),
                            ],
                            1
                          )
                        : _c("span", [_vm._v(" Remover da grade ")]),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-delete-lessons",
            centered: "",
            title: "Deletar aulas selecionadas ",
            "ok-only": "",
            "ok-title": "Deletar",
            "ok-variant": "success",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            visible: _vm.isModalGridLessonsDeleteActive,
            "hide-footer": "",
          },
          on: { hide: _vm.onHide },
        },
        [
          _c("b-card-text", [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitDeleteLessons.apply(null, arguments)
                  },
                },
              },
              [
                _c("h5", [_vm._v("Aulas ")]),
                _c("p", [
                  _vm._v("Selecione as aulas que você pretende deletar."),
                ]),
                _vm._l(_vm.item.disciplines, function (discipline, index) {
                  return _c("b-list-group-item", { key: index }, [
                    _c("span", [_vm._v(" " + _vm._s(discipline.title) + " ")]),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        staticStyle: {
                          "list-style": "none",
                          "line-height": "2.5",
                          padding: "0px",
                        },
                      },
                      _vm._l(discipline.lessons, function (item, i) {
                        return _c("li", { key: "" + i }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              [
                                _c("b-form-checkbox", {
                                  staticClass: "custom-control-primary",
                                  attrs: {
                                    checked: true,
                                    value: item.uuid,
                                    name: "discipline-" + index + "-" + i,
                                    id: "discipline-" + index + "-" + i,
                                    switch: "",
                                  },
                                  model: {
                                    value: _vm.formatDeleteLessons,
                                    callback: function ($$v) {
                                      _vm.formatDeleteLessons = $$v
                                    },
                                    expression: "formatDeleteLessons",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("datePT")(item.date)) +
                                  " - " +
                                  _vm._s(_vm.formatTimer(item.start_time)) +
                                  " às " +
                                  _vm._s(_vm.formatTimer(item.end_time)) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ])
                }),
                _c("hr"),
                _c("div", { staticClass: "form-row justify-content-end" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light btn-block",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.isModalGridLessonsDeleteActive = false
                          },
                        },
                      },
                      [_vm._v(" Fechar ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger btn-block",
                        attrs: {
                          disabled: !_vm.formatDeleteLessons.length,
                          type: "submit",
                        },
                      },
                      [
                        _vm.submittedDeleteLessons
                          ? _c(
                              "span",
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", variant: "light" },
                                }),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("Deletar aulas")]),
                      ]
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-edit-lessons",
            centered: "",
            title: "Editar aulas",
            "ok-only": "",
            "ok-title": "Editar aulas",
            "ok-variant": "success",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            visible: _vm.isModalGridLessonsEditActive,
            "modal-class": "modal-edit-lessons",
            "hide-footer": "",
          },
          on: { hide: _vm.onHide },
        },
        [
          _c("b-card-text", [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitEditLessons.apply(null, arguments)
                  },
                },
              },
              [
                _c("h5", [_vm._v("Aulas ")]),
                _c("p", [
                  _vm._v(
                    "Altere as informações das aulas que você deseja editar."
                  ),
                ]),
                _c(
                  "app-collapse",
                  {
                    staticClass: "collapse-disciplines",
                    attrs: { accordion: "", type: "margin" },
                  },
                  _vm._l(_vm.formEditLessons, function (discipline, index) {
                    return _c(
                      "app-collapse-item",
                      {
                        key: "disc-" + index,
                        attrs: {
                          title:
                            "(" +
                            discipline.lessons.length +
                            ") - " +
                            discipline.title,
                        },
                      },
                      [
                        _vm._l(discipline.lessons, function (lesson, i) {
                          return _c(
                            "div",
                            {
                              key: "lesson-" + i,
                              staticClass: "form-row mb-1",
                              staticStyle: { "align-items": "center" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c("flat-pickr", {
                                    staticClass: "form-control",
                                    attrs: {
                                      config: {
                                        altInput: true,
                                        dateFormat: "Y/m/d",
                                        altFormat: "d/m/Y",
                                        locale: "pt",
                                      },
                                    },
                                    model: {
                                      value: lesson.date,
                                      callback: function ($$v) {
                                        _vm.$set(lesson, "date", $$v)
                                      },
                                      expression: "lesson.date",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "col-md-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex",
                                    staticStyle: { "align-items": "center" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: lesson.start_time,
                                          expression: "lesson.start_time",
                                        },
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: ["##:##"],
                                          expression: "['##:##']",
                                        },
                                      ],
                                      staticClass: "form-control mr-1",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.formEditLessons.$each[index]
                                            .lessons.$each[i].start_time.$error,
                                      },
                                      attrs: { type: "tel" },
                                      domProps: { value: lesson.start_time },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            lesson,
                                            "start_time",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: lesson.end_time,
                                          expression: "lesson.end_time",
                                        },
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: ["##:##"],
                                          expression: "['##:##']",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.formEditLessons.$each[index]
                                            .lessons.$each[i].end_time.$error,
                                      },
                                      attrs: { type: "tel" },
                                      domProps: { value: lesson.end_time },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            lesson,
                                            "end_time",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c(
                                    "v-select",
                                    {
                                      class: {
                                        "is-invalid":
                                          _vm.$v.formEditLessons.$each[index]
                                            .lessons.$each[i].category.$error,
                                      },
                                      attrs: {
                                        searchable: false,
                                        label: "title",
                                        "item-text": "title",
                                        "item-value": "code",
                                        placeholder: "Categoria",
                                        options: _vm.optionsGridLessonCategory,
                                      },
                                      model: {
                                        value: lesson.category,
                                        callback: function ($$v) {
                                          _vm.$set(lesson, "category", $$v)
                                        },
                                        expression: "lesson.category",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "no-options" },
                                          slot: "no-options",
                                        },
                                        [_vm._v(" Nenhum registro encontrado ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        !discipline.lessons.length
                          ? _c("p", { staticClass: "mb-0 p-1" }, [
                              _c("i", { staticClass: "bi bi-info-circle" }),
                              _vm._v(
                                " Nenhnuma aula cadastrada até o momento. "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  1
                ),
                _c("hr"),
                _c("div", { staticClass: "form-row justify-content-end" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light btn-block",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.isModalGridLessonsEditActive = false
                          },
                        },
                      },
                      [_vm._v(" Fechar ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-block",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm.submittedEditLessons
                          ? _c(
                              "span",
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", variant: "light" },
                                }),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("Editar aulas")]),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-import-grid",
            centered: "",
            title: "Importar grade ",
            "ok-only": "",
            "ok-title": "Importar",
            "ok-variant": "success",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            visible: _vm.isModalGridLessonImportActive,
            "hide-footer": "",
          },
          on: { hide: _vm.onHide },
        },
        [
          _c("b-card-text", [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitLessonImports.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "d-block mb-2" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Qual grade deseja importar? "),
                          ]),
                          _c("v-select", {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Digite o título",
                              options: _vm.optionsGrids,
                            },
                            on: {
                              search: _vm.fetchGrid,
                              input: _vm.gridSelected,
                            },
                            model: {
                              value: _vm.formImportLessons.grid,
                              callback: function ($$v) {
                                _vm.$set(_vm.formImportLessons, "grid", $$v)
                              },
                              expression: "formImportLessons.grid",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.optionsDisciplines.length
                      ? _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Selecione 1 ou mais disciplinas "),
                              ]),
                              _c(
                                "b-list-group",
                                _vm._l(
                                  _vm.optionsDisciplines,
                                  function (discipline, index) {
                                    return _c(
                                      "b-list-group-item",
                                      { key: index },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex" },
                                          [
                                            _c("b-form-checkbox", {
                                              staticClass:
                                                "custom-control-primary",
                                              attrs: {
                                                checked: true,
                                                value: discipline.code,
                                                name: "discipline-" + index,
                                                id: "discipline-" + index,
                                                switch: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.formImportLessons
                                                    .disciplines,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formImportLessons,
                                                    "disciplines",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formImportLessons.disciplines",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(discipline.title) +
                                                  " "
                                              ),
                                              _c("small", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(discipline.lessons) +
                                                    ")"
                                                ),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [
                            _vm._v(
                              " Deseja importar a partir de alguma data? "
                            ),
                          ]),
                          _c("flat-pickr", {
                            staticClass: "form-control",
                            attrs: {
                              config: {
                                dateFormat: "d/m/Y",
                                altFormat: "Y-m-d",
                                locale: "pt",
                                enableTime: false,
                                wrap: true,
                              },
                            },
                            model: {
                              value: _vm.formImportLessons.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.formImportLessons, "date", $$v)
                              },
                              expression: "formImportLessons.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("small", [
                        _c("i", { staticClass: "bi bi-info-circle" }),
                        _vm._v(
                          " Se nenhuma data for selecionada, as aulas programadas para ocorrer a partir de hoje serão importadas."
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("hr"),
                _c("div", { staticClass: "form-row justify-content-end" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light btn-block",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.isModalGridLessonImportActive = false
                          },
                        },
                      },
                      [_vm._v(" Fechar ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-block",
                        attrs: { disabled: _vm.submitedImport, type: "submit" },
                      },
                      [
                        _vm.submitedImport
                          ? _c(
                              "span",
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", variant: "light" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("span", [_vm._v(" Importar ")]),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-lessons-exists",
            centered: "",
            title: "Horários já reservados",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-footer": "",
          },
        },
        [
          _c("p", { staticClass: "alert alert-danger p-1" }, [
            _vm._v(
              "Ops..não foi possível cadastrar as aulas, pois encontramos alguns conflitos referente a: data, horário e professor(a)."
            ),
          ]),
          _c("b-table", {
            staticClass: "mb-0",
            attrs: {
              responsive: "",
              items: _vm.lessonsExist,
              fields: _vm.tableFieldsLessonsExists,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(grid_id)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "text-link",
                        attrs: {
                          href:
                            "/pedagogical/units/grids/" +
                            item.grid_uuid +
                            "/edit",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.grid_id) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(start)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(item.start) + " às " + _vm._s(item.end) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-lesson-to-grid",
            "modal-class": "modal-create",
            title: "Vincular aula a outra grade",
            centered: "",
            size: "lg",
            "hide-footer": "",
          },
        },
        [
          _c("h5", [
            _c("i", { staticClass: "text-danger bi bi-record-circle" }),
            _vm._v(" Escolha a turma que você deseja montar grade."),
          ]),
          _c("div", { staticClass: "alert alert-primary" }, [
            _c("div", { staticClass: "alert-body" }, [
              _vm._v(" Uma turma só poderá ter 1 grade. "),
              _c("br"),
              _vm._v(
                "Se a turma não aparecer na listagem, talvez já esteja vinculada a outra grade. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-10" },
              [
                _c(
                  "v-select",
                  {
                    class: {
                      "is-invalid": _vm.$v.formLessonToGrid.grid.$error,
                    },
                    attrs: {
                      label: "title",
                      "item-text": "title",
                      "item-value": "code",
                      placeholder: "Digite o CÓDIGO DA TURMA. Ex.: DL259",
                      options: _vm.optionsLessonToGrids,
                    },
                    on: { search: _vm.fetchLessonToGrid },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            _c("span", { class: option.icon }),
                            _vm._v(" " + _vm._s(option.title) + " "),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.formLessonToGrid.grid,
                      callback: function ($$v) {
                        _vm.$set(_vm.formLessonToGrid, "grid", $$v)
                      },
                      expression: "formLessonToGrid.grid",
                    },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "no-options" }, slot: "no-options" },
                      [_vm._v(" Nenhum registro encontrado ")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-primary",
                  staticStyle: { padding: "15px 10px !important" },
                  on: { click: _vm.submitLessonToGrid },
                },
                [_vm._v(" Montar Grade ")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-lesson-delete",
            "modal-class": "modal-create",
            title: "Esta aula está vinculada a mais de uma grade",
            centered: "",
            size: "sm",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          _c("div", { staticClass: "alert alert-danger p-1" }, [
            _c("h4", { staticClass: "text-danger mb-1" }, [
              _c("strong", [_vm._v("Modo de exclusão da aula")]),
            ]),
            _c("p", { staticClass: "text-danger" }, [
              _vm._v(" Você pode excluir esta aula "),
              _c("strong", [_c("u", [_vm._v("apenas desta grade")])]),
              _vm._v(" ou de "),
              _c("strong", [_c("u", [_vm._v("todas as grades")])]),
              _vm._v(". "),
            ]),
          ]),
          _c("v-select", {
            attrs: {
              searchable: false,
              label: "title",
              "item-text": "title",
              "item-value": "code",
              placeholder: "Selecione",
              options: _vm.optionsModeDeleteLesson,
            },
            model: {
              value: _vm.lessonDeleteMode,
              callback: function ($$v) {
                _vm.lessonDeleteMode = $$v
              },
              expression: "lessonDeleteMode",
            },
          }),
          _c(
            "button",
            {
              staticClass: "btn btn-danger btn-block mt-2",
              attrs: { disabled: _vm.loading.lessonDelete, type: "button" },
              on: { click: _vm.optionDeleteSelected },
            },
            [_vm._v(" Confirmar ")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-lesson-delete-discipline",
            "modal-class": "modal-create",
            title: "Exclusão de aulas por disciplinas",
            centered: "",
            size: "sm",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          _c("div", { staticClass: "alert alert-danger p-1" }, [
            _c("h4", { staticClass: "text-danger mb-1" }, [
              _c("strong", [_vm._v("Modo de exclusão das aulas")]),
            ]),
            _c("p", { staticClass: "text-danger" }, [
              _vm._v(" Você pode excluir as aulas "),
              _c("strong", [_c("u", [_vm._v("apenas desta grade")])]),
              _vm._v(" ou de "),
              _c("strong", [_c("u", [_vm._v("todas as grades")])]),
              _vm._v(". "),
            ]),
          ]),
          _c("v-select", {
            attrs: {
              searchable: false,
              label: "title",
              "item-text": "title",
              "item-value": "code",
              placeholder: "Selecione",
              options: _vm.optionsModeDeleteLesson,
            },
            model: {
              value: _vm.lessonDeleteMode,
              callback: function ($$v) {
                _vm.lessonDeleteMode = $$v
              },
              expression: "lessonDeleteMode",
            },
          }),
          _c(
            "button",
            {
              staticClass: "btn btn-danger btn-block mt-2",
              attrs: { disabled: _vm.loading.lessonDelete, type: "button" },
              on: { click: _vm.deleteLessonByDiscipline },
            },
            [_vm._v(" Confirmar ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header mb-0" }, [
      _c("h4", { staticClass: "mb-0" }, [_vm._v(" Disciplinas ")]),
      _c("small", [
        _vm._v("Em todas as aulas, está sendo subtraído o tempo de intervalo."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }